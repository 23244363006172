<template>
  <div class="flex flex-col content-center justify-center items-center w-screen min-w-screen min-h-screen">
    <div class="relative">
      <img
        v-show="!hideLogo"
        src="/cdn/img/logo/logo-512.png"
        alt=""
        class="mx-auto w-12 sm:w-16 md:w-24 lg:w-32 h-12 sm:h-16 md:h-24 lg:h-32 mb-4"
      >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useHead } from '@vueuse/head'

import useFontSizeGlobal from '@/hooks/useFontSizeGlobal'
import useWindowCacheRefresh from '@/hooks/useWindowCacheRefresh'

defineProps({
  hideLogo: {
    type: Boolean,
    default: false,
  },
})
const fontSize = useFontSizeGlobal()
const htmlAttrs = computed(() => ({
  class: [`font-size-${fontSize.value}`],
}))
useHead({ htmlAttrs })

useWindowCacheRefresh({ watchQuery: true })
</script>
