<template>
  <div class="flex flex-col gap-3">
    <h3>Ben je zeker dat je rit #{{ id }} wil verwijderen?</h3>
    <button class="btn success inline-block" @click="handleSubmit">
      Ja, verwijderen
    </button>
    <small class="text-red-500 font-bold text-sm">Opgelet deze actie is onomkeerbaar!</small>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  id: [Number, String],
})
const emit = defineEmits(['close'])
const api = useApi()
const form = ref({
  rit_id: props.id,
})

const handleSubmit = () => {
  api('RIT_D', form).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
