<template>
  <div class="flex flex-col gap-4">
    <h3>Rit #{{ rit.id }} - Facturatie</h3>

    <UIInputCheckbox v-model="form.is_factuur_nodig" label="Is factuur nodig?" />

    <button class="btn py-4 success inline-block" @click="handleSubmit(true)">
      Ja, bevestig MET mail
    </button>
    <button class="btn py-4 g-orange-200 hover:bg-orange-500 text-orange-900 inline-block" @click="handleSubmit(false)">
      Ja, bevestig ZONDER mail
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInputCheckbox from '@/components/UI/Input/Checkbox.vue'

import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        is_factuur_nodig: false,
      }
    },
  },
})
const emit = defineEmits(['close'])
const api = useApi()
const form = ref({
  rit_id: props.rit.id,
  is_factuur_nodig: props.rit.is_factuur_nodig,
})

const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const handleSubmit = mail => {
  console.warn('[In development]:', { mail, form: form.value, rit: vModelRit.value })
  if (Number(false) < 1) throw new Error('In ontwikkeling')
  api('@TODO:', { mail, ...form.value }).then(() => {
    vModelRit.value.is_factuur_nodig = form.value.is_factuur_nodig
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
