import useRouteQueryBase from './_useRouteQueryBase'

import { isThisNaN } from '@/functions/isThisNaN'

const defaultValueFn = () => null
const parseFn = x => Number.parseInt(x || 0, 10)

const checkFn = x => x >= 0 && !isThisNaN(parseFn(x))

export default useRouteQueryBase(checkFn, parseFn, defaultValueFn)

// import { useRoute, useRouter } from 'vue-router'
// import { watch, ref } from 'vue'
// import useRouteQuerySet from './useRouteQuerySet'

// export default (queryName, defaultValue = null) => {
//   const route = useRoute()
//   const router = useRouter()
//   const reactiveValue = ref(route.query[queryName] > 0 ? parseInt(route.query[queryName], 10) : defaultValue)
//   const setRoute = val => {
//     let newVal = parseInt(val, 10)
//     if (isThisNaN(newVal) || (!val && val !== 0)) newVal = null
//     useRouteQuerySet(queryName, newVal, router, route)
//   }
//   watch(() => reactiveValue.value, setRoute)
//   setRoute(reactiveValue.value)
//   return reactiveValue
// }
