<template>
  <div v-if="model" class="flex flex-col gap-4 max-w-sm">
    <UISelectFixed v-model="model.type" type="BLACKLIST_WHITELIST_TYPE" class="w-full" />
    <UIInput
      v-model="model.value"
      :label="`Waarde (${model.type.replace('_', ' ')})`"
      class="w-full"
      placeholder="Waarde ..."
    />
    <UIInput
      v-model="model.opmerking"
      textarea
      rows="4"
      label="Opmerking"
      class="w-full"
      placeholder="Opmerking ..."
    />

    <div :key="model.count" class="flex flex-col gap-2">
      <UIInput
        v-if="apiData.whitelist === 'VIP' && model.count !== 99999"
        v-model="model.count"
        type="number"
        label="VIP Experiences"
        class="w-full"
        placeholder="Aantal keer VIP"
      />
      <UIInputCheckbox
        v-if="apiData.whitelist === 'VIP'"
        :model-value="!!(model.count === 99999)"
        label="Altijd VIP?"
        class="w-full"
        @update:model-value="() => model.count = model.count === 99999 ? 0 : 99999"
      />
    </div>

    <button class="btn success mt-4" @click="handleSubmit">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import { useVModel } from '@vueuse/core'
import clone from 'just-clone'

import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInput from '@/components/UI/Input/Input'
import UISelectFixed from '@/components/UI/Select/Fixed'

import useApi from '@/hooks/useApi'

const props = defineProps({
  apiData: {
    type: Object,
    default() {
      return {}
    },
  },
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        type: 'EMAIL',
        value: '',
        opmerking: '',
      }
    },
  },
})

const emit = defineEmits(['update:modelValue', 'close'])
const api = useApi()
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
const isEdit = model.value.id > 0

const handleSubmit = () => {
  const data = { ...clone(model.value), ...props.apiData }

  api(isEdit ? 'WHITELIST_BLACKLIST_EDIT' : 'WHITELIST_BLACKLIST_CREATE', data).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
