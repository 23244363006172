<template>
  <p v-if="ritReedsBetaald" class="px-0 text-center text-red-500">
    Rit werd reeds volledig betaald!
  </p>
  <div v-else class="flex flex-col gap-3 md:max-w-md">
    <UISelectChauffeur
      v-if="store.getters.hasMasterPermission"
      v-model="betaling.user_id"
      class="w-full"
      active
      label
    />
    <UIInputDatePicker v-model:date="betaling.date" label="Betaal datum" class="text-left" />
    <UISelectFixed
      v-model="betaling.methode"
      :type="isInWagen ? 'BETAALMETHODE_CHAUFFEUR' : 'BETAALMETHODE'"
      class="w-full"
      label="Betaalmethode"
    />
    <div v-if="betaling.methode === 'QR'" class="mt-4 flex items-center justify-center">
      <img
        v-if="qrCode?.src"
        :src="qrCode.src"
        alt="QR Code"
        class="w-full max-w-xs"
      >
      <button
        v-else
        :disabled="api.loading.value"
        class="btn info inline-block"
        @click="handleQRCodeGenereren"
      >
        QR Code genereren
      </button>
    </div>
    <template v-else>
      <div class="flex flex-col">
        <UIInput
          v-model="betaling.bedrag"
          label="Bedrag"
          type="number"
          :max="openSaldo"
          class="w-full"
          placeholder="Bedrag ..."
          @blur="() => betaling.bedrag = Math.min(betaling.bedrag, openSaldo)"
        />
        <small v-if="betaling.bedrag > openSaldo" class="text-sm">Openstaand saldo: &euro;&nbsp;{{ formatPrice(openSaldo) }}</small>
      </div>
      <UIInput
        v-model="betaling.opmerking"
        textarea
        label="Opmerking over de ontvangen betaling"
        rows="4"
        class="w-full"
        placeholder="Eventuele opmerking ..."
      />
      <button class="btn success inline-block" @click="handleOpslaan">
        Opslaan
      </button>
      <div class="flex flex-col justify-center text-center">
        <small class="text-indigo-400 italic"><b>Gegevens goed nakijken alvorens het opslaan!</b></small>
        <small class="italic text-sm">{{ betaling.methode }} ontvangen {{ betaling.bedrag }} EUR.</small>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

import { dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import useApi from '@/hooks/useApi'

const props = defineProps({
  isInWagen: Boolean,
  callback: Function,
  user_id: Number,
  rit: {
    type: Object,
    default() {
      return {
        prijs: 0,
        bedrag_betaald: 0,
        bedrag_ontvangen: 0,
        bedrag_open_saldo: 0,
        // datum - kan bij kalender
        // vertrek_datetime - kan bij alle andere gevallen
      }
    },
  },
})

const emit = defineEmits(['close', 'update:rit'])
const store = useStore()
const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

// const ritPrijs = computed(() => Math.max(0, (props.rit?.bedrag_saldo || 0)))
const openSaldo = computed(() => Math.max(0, (props.rit?.bedrag_open_saldo || 0)))
const ritReedsBetaald = computed(() => openSaldo.value <= 0)

const betaling = ref({
  rit_id: props.rit.id,
  bedrag: props.rit ? Math.max(0, openSaldo.value) : 0,
  user_id: (props.user_id > 0 ? props.user_id : (store.getters.currentUserId || null)),
  date: dateString(props.rit?.datum || props.rit?.vertrek_datetime || new Date()),
  methode: 'CASH',
  opmerking: null,
})

const api = useApi()
const handleOpslaan = () => {
  if (!store.getters.hasMasterPermission) {
    betaling.value.user_id = store.getters.currentUserId
  }
  api('BETALING_CREATE', betaling).then(() => {
    vModelRit.value.bedrag_betaald = (vModelRit.value.bedrag_betaald || 0) + Number(betaling.value.bedrag)
    vModelRit.value.bedrag_ontvangen = (vModelRit.value.bedrag_ontvangen || 0) + Number(betaling.value.bedrag)
    vModelRit.value.bedrag_open_saldo = (vModelRit.value.bedrag_open_saldo || 0) - Number(betaling.value.bedrag)
    if (betaling.value.methode !== 'CASH') {
      vModelRit.value.is_enkel_cash_betaald = false
    }
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close')
  })
}

const qrCode = ref({})
const handleQRCodeGenereren = () => {
  api('RIT_BETALING_QR_CODE', { rit_id: props.rit.id }).then(({ data }) => {
    if ('src' in data?.qrCode) {
      qrCode.value = data.qrCode
      return
    }

    alert('QR Code genereren mislukt!')
  })
}
</script>
