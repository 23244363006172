<template>
  <div :key="selected">
    <UIInputLabel
      v-if="label"
      :id="id"
      :required="required"
      :focussed="focussed"
    >
      {{ label }}
    </UIInputLabel>

    <select
      :id="id"
      v-bind="$attrs"
      v-model="selected"
      class="cursor-pointer"
      :class="{ 'text-gray-400 font-normal': selected === '' }"
      @focus="focussed = true"
      @blur="focussed = false"
    >
      <option
        v-if="placeholderText"
        value=""
        disabled
        selected
        hidden
      >
        {{ placeholderText }}
      </option>
      <option
        v-for="(item, index) in options"
        :key="`${item.value}${item}${index}`"
        :value="typeof item.value !== 'undefined' ? item.value : item"
        :selected="selected == (typeof item.value !== 'undefined' ? item.value : selected == item)"
        :disabled="item.disabled"
        :class="{ [item.class || '']: item.value === null }"
      >
        {{ item.label || item }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineOptions, defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'

import UIInputLabel from '@/components/UI/Input/Label.vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  label: String,
  required: Boolean,
  id: {
    type: [Number, String],
    default: uuidv4,
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [Number, String],
    default() {
      return null
    },
  },
  options: {
    type: Array,
    default() {
      return []
    },
  },
})

const emit = defineEmits(['update:modelValue'])
const selected = useVModel(props, 'modelValue', emit, { deep: true, passive: true, defaultValue: undefined })
const focussed = ref(false)
const placeholderText = computed(() => {
  if (!props.placeholder) return ''
  const result = props.placeholder.trim()
  if (result.endsWith('...')) return result
  return `${result} ...`
})
</script>
