<template>
  <div>
    <div :key="Object.values(apiData).join('-')" class="py-4">
      <UITableResponsive
        :items="items"
        :headers="['Verzonden', 'Email', 'Sjabloon', 'Geopend', 'URL_Geklikt', 'Spam', 'Blocked', 'Bounce']"
        :suffix-headers="['Acties']"
        no-data-text="Geen mails gevonden"
      >
        <template #colgroup>
          <colgroup>
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-auto">
            <col class="w-0">
          </colgroup>
        </template>
        <template #item-Verzonden="{ item }">
          <span v-if="item.events.sent?.length" class="font-bold text-red-green">{{ dateTimeStringLocale(item.events.sent[0]) }}</span>
          <small v-if="item.message_guid.includes('MANUEEL')" class="italic text-sm block text-gray-500">(Manueel verzonden)</small>
        </template>
        <template #item-Email="{ item }">
          {{ item.email }}
        </template>
        <template #item-Sjabloon="{ item }">
          <span>{{ String(item.subject).split('_').join(' ') }}</span>
        </template>
        <template #item-Geopend="{ item }">
          <span v-if="item.message_guid == 'MANUEEL'">/</span>
          <UILabelJaNeen v-else :value="item.events?.open?.length > 0" />
        </template>
        <template #item-URL_Geklikt="{ item }">
          <span v-if="item.message_guid == 'MANUEEL'">/</span>
          <UILabelJaNeen v-else :value="item.events?.click?.length > 0" />
        </template>
        <template #item-Spam="{ item }">
          <span v-if="item.message_guid == 'MANUEEL'">/</span>
          <UILabelJaNeen v-else :value="item.events?.spam?.length > 0" />
        </template>
        <template #item-Blocked="{ item }">
          <span v-if="item.message_guid == 'MANUEEL'">/</span>
          <UILabelJaNeen v-else :value="item.events?.blocked?.length > 0" />
        </template>
        <template #item-Bounce="{ item }">
          <span v-if="item.message_guid == 'MANUEEL'">/</span>
          <UILabelJaNeen v-else :value="item.events?.bounce?.length > 0" />
        </template>
        <template #item-Acties="{ item }">
          <div class="flex flex-row gap-2">
            <!-- @TODO: Mailjet more info button/modal -->
            <button class="btn bg-purple-500 text-purple-100" :disabled="true">
              Meer info
            </button>
            <ButtonRitInfo v-if="!rit_id && item.rit_id > 0" :id="item.rit_id" target="_self" />
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>

    <div v-if="!hideLegend" class="text-xs lg:text-sm italic card inline-block">
      <b>Legende:</b>
      <ul class="flex flex-col gap-2 mt-1">
        <li class="text-green-500">
          <b>Geopend</b>: Heeft de klant de mail geopend?
        </li>
        <li class="text-green-500">
          <b>URL Geklikt</b>: Heeft de klant in de mail een url aangeklikt
        </li>
        <li class="text-orange-400">
          <b>Spam</b>: Heeft mailsysteem melding dat de mail hoogwaarschijnlijk in spam is terecht gekomen
        </li>
        <li class="text-red-500">
          <b>Blocked</b>: Heeft mailsysteem melding dat de mail werd geblokeerd door email provider klant
        </li>
        <li class="text-red-500">
          <b>Bounce</b>: Heeft mailsysteem melding dat de email niet is toegekomen? (Vb: verkeerde mail klant)
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
// import clone from 'just-clone'
import { computed, defineAsyncComponent, watch } from 'vue'

import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
import UILabelJaNeen from '@/components/UI/Labels/JaNeen'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { dateTimeStringLocale } from '@/functions/formatDate'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const props = defineProps({
  subject: [String, Array],
  rit_id: [Number, String],
  klant_id: [Number, String],
  facturatie_id: [Number, String],
  doc_number: [Number, String],
  user_id: [Number, String],
  hideLegend: Boolean,
})

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const apiData = computed(() => ({
  subject: props.subject || null,
  rit_id: props.rit_id || null,
  klant_id: props.klant_id || null,
  facturatie_id: props.facturatie_id || null,
  doc_number: props.doc_number || null,
  user_id: props.user_id || null,
}))

const { limit, offset, count, items } = usePaginatedApi('/api/dashboard/mails/list', apiData)

watch(apiData, () => {
  offset.value = 0
})
</script>
