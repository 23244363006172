<template>
  <div class="flex flex-col gap-4">
    <h3>Rit #{{ rit.id }} annuleren?</h3>
    <UIInput
      v-model="form.kosten_annulatie"
      type="number"
      label="Eventuele annulatie kosten"
      class="w-full max-w-2xl"
      placeholder="Annulatie kosten"
    />
    <div v-if="errorCount > 0" class="flex flex-col gap-2">
      <UICheckbox v-model="form.forceer" label="Forceer annulatie" />
      <small class="text-xs italic px-2">Enkel als je absoluut zeker bent dat annuleren correct boekhoudkundig is!</small>
    </div>
    <button class="btn py-4 error inline-block" @click="handleSubmit(true)">
      Ja, annuleer MET mail
    </button>
    <button class="btn py-4 g-orange-200 hover:bg-orange-500 text-orange-900 inline-block" @click="handleSubmit(false)">
      Ja, annuleer ZONDER mail
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useVModel } from '@vueuse/core'

import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  uit_onbevestigden: Boolean,
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        is_bevestigd: false,
        is_geannuleerd: false,
      }
    },
  },
})
const emit = defineEmits(['close'])
const api = useApi()
const form = ref({
  kosten_annulatie: 0,
  rit_id: props.rit.id,
  uit_onbevestigden: !!props.uit_onbevestigden,
  forceer: false,
})

const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const errorCount = ref(0)

const handleSubmit = mail => {
  api('RIT_ANNULEER', { mail, ...form.value }).then(() => {
    vModelRit.value.is_bevestigd = false
    vModelRit.value.is_geannuleerd = true
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  }).catch(() => {
    errorCount.value++
  })
}
</script>
