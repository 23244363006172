<template>
  <div class="flex h-screen overflow-hidden" style="min-width: 320px">
    <div v-if="axiosLoading" class="fixed w-full h-full h-screen bg-gray-100 opacity-75 flex justify-center items-center gap-8">
      <UILoading />
      <span class="font-bold text-2xl">Aan het laden ...</span>
    </div>
    <div class="flex flex-row flex-1 w-full">
      <UISidebar v-model="toggled" v-on="{ click: () => search = '' }" />

      <div class="flex flex-col w-0 flex-1 overflow-x-hidden">
        <div class="relative flex-shrink-0 flex h-16 bg-white shadow border-t border-gray-200">
          <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="toggled = !toggled">
            <span class="sr-only">Open sidebar</span>
            <!-- Heroicon name: outline/menu-alt-2 -->

            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </button>

          <div class="flex-1 px-4 flex justify-between">
            <div class="flex-1 flex">
              <form
                v-if="store.getters.hasAdminPermission"
                class="w-full flex md:ml-0"
                action="#"
                method="GET"
              >
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </div>
                  <input
                    :id="SEARCH_ID"
                    :ref="el => searchInput = el"
                    v-model="search"
                    class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Zoeken op klant of bedrijf"
                    type="search"
                    name="search"
                    @input="search = $event?.target?.value || ''"
                  >
                </div>
              </form>
            </div>
            <div class="ml-4 flex items-center md:ml-6 gap-2">
              <!-- <ButtonGeolocation v-if="store.getters.hasChauffeurOrOnderaannemerPermission" /> -->
              <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="handleFontSize">
                <span class="sr-only">Change font size</span>
                <i class="h-6 w-6 fas fa-text-height" aria-hidden="true"></i>
              </button>
              <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="toggleFullReload">
                <span class="sr-only">Reload toggle</span>
                <i class="h-6 w-6 fa fa-sync" aria-hidden="true"></i>
              </button>
              <button
                v-if="!store.getters.isExpoAppWebview"
                type="button"
                class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="toggleFullscreen"
              >
                <span class="sr-only">Fullscreen toggle</span>
                <i class="h-6 w-6 fas" :class="{ 'fa-compress': isFullscreen, 'fa-expand': !isFullscreen }" aria-hidden="true"></i>
              </button>

              <!-- <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">View notifications</span>
                <i class="h-6 w-6 fas fa-bell" aria-hidden="true" />
              </button> -->

              <!-- Profile dropdown -->
              <Menu as="div" class="ml-2 relative">
                <div>
                  <MenuButton class="max-w-xs bg-white flex items-center text-base rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src="/cdn/img/logo/logo-120.png" alt="">
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-tooltip">
                    <MenuItem v-for="item in userNavigationFiltered" :key="item.name" v-slot="{ active }">
                      <a
                        :href="item.href"
                        class="block px-4 py-2 text-sm text-gray-700"
                        :class="[active ? 'bg-gray-100' : '']"
                        @click.prevent="item.onClick"
                      >{{ item.name }}</a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1 relative overflow-y-auto focus:outline-none">
          <SearchList v-show="store.getters.hasKantoorPermission && search" :search="search" />
          <div v-show="!search" class="py-6 pb-12 lg:pb-18">
            <div class="mx-auto px-3 sm:px-4 md:px-6 lg:px-8">
              <ErrorBoundery>
                <slot></slot>
              </ErrorBoundery>
            </div>
          </div>
        </main>
      </div>
    </div>
    <NotificationsCalls v-if="false && store.getters.hasKantoorPermission" />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useFullscreen, useWindowSize } from '@vueuse/core'
import {
  // Dialog,
  // DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  // TransitionChild,
  // TransitionRoot,
} from '@headlessui/vue'

import ErrorBoundery from '@/components/ErrorBoundery.vue'
import UILoading from '@/components/UI/Loading'
import NotificationsCalls from '@/components/UI/Notifications/Calls.vue'
// import ButtonGeolocation from '@/components/UI/Button/Geolocation'
import UISidebar from '@/components/UI/Sidebar/Sidebar'

import { hasRoutePathPermission } from '@/functions/hasRoutePermission'
import useAuthLogout from '@/hooks/useAuthLogout'
import useModal from '@/hooks/useModal'
import { SEARCH_ID, default as useSearchGlobal } from '@/hooks/useSearchGlobal'
import useWindowCacheBust from '@/hooks/useWindowCacheBust'
import useWindowCacheRefresh from '@/hooks/useWindowCacheRefresh'

const axiosLoading = inject('axiosLoading')

const SearchList = defineAsyncComponent(() => import('@/components/Table/Search/List.vue'))

// import useWindowCacheBust from '@/hooks/useWindowCacheBust'
// const toggleFullReload = () => useWindowCacheBust(() => {
//   window.location.href = String(window.location.href)
// })

const refresh = useWindowCacheRefresh({ watchQuery: true })
const toggleFullReload = () => useWindowCacheBust(refresh)

const router = useRouter()
const route = useRoute()
const store = useStore()
const [openFixedModal] = useModal({ fixed: true })

const search = useSearchGlobal()
const searchInput = ref(null)
watch(route, () => search.value = '')

const toggled = ref(false)
const { isFullscreen, /* enter, exit, */ toggle: toggleFullscreen } = useFullscreen()

const handleFontSize = () => {
  openFixedModal('FORM_RIT_FONT_SIZE')
}

const userNavigation = [
  { name: 'Jouw planning', href: '/chauffeur/planning', onClick: () => router.push('/chauffeur/planning') },
  { name: 'Wachtwoord wijzigen', href: '/settings#wachtwoord', onClick: () => router.push('/settings#wachtwoord') },
  { name: 'Instellingen', href: '/settings', onClick: () => router.push('/settings') },
  { name: 'Uitloggen', href: '#logout', onClick: useAuthLogout() },
]

const userNavigationFiltered = computed(() => userNavigation.filter(el => hasRoutePathPermission(el.href)))

if (typeof window !== 'undefined') {
  toggled.value = useWindowSize({ window })?.width?.value >= 1024
}
onMounted(() => {
  window?.addEventListener('resize', () => {
    toggled.value = useWindowSize({ window })?.width?.value >= 1024
  })
})

// onMounted(() => {
//   window.addEventListener('keydown', (e) => {
//     if(e.key == 'f' && (e.ctrlKey || e.metaKey)) {
//       if (searchInput.value) {
//         e.preventDefault()
//         searchInput.value.focus()
//       }
//     }
//   })
// })
</script>
