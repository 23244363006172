<template>
  <div class="flex flex-col gap-4">
    <h3>Rit #{{ rit.id }} bevestigen?</h3>
    <button class="btn py-4 success inline-block" @click="handleSubmit(true)">
      Ja, bevestig MET mail
    </button>
    <button class="btn py-4 bg-orange-200 hover:bg-orange-500 text-orange-900 inline-block" @click="handleSubmit(false)">
      Ja, bevestig ZONDER mail
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useVModel } from '@vueuse/core'

import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        is_bevestigd: false,
        is_geannuleerd: false,
      }
    },
  },
})
const emit = defineEmits(['close'])
const api = useApi()
const form = ref({
  rit_id: props.rit.id,
})

const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const handleSubmit = mail => {
  api('RIT_BEVESTIG', { mail, ...form.value }).then(() => {
    vModelRit.value.is_bevestigd = true
    vModelRit.value.is_geannuleerd = false
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
