<template>
  <div class="flex flex-col">
    <h2 class="mb-0">
      Huidige status
    </h2>
    <div v-if="rit.voucher_gegeven">
      <p class="text-sm text-green-500 font-bold">
        Voucher "{{ rit.voucher_gegeven }}" reeds gegeven voor rit
      </p>
      <div class="flex flex-row gap-2 lg:gap-6 mt-4">
        <button :disabled="api.loading.value" class="btn small error inline-block" @click="handleVerwijderen">
          Voucher deactiveren
        </button>
        <button :disabled="api.loading.value" class="btn small warn inline-block" @click="handleOpslaan">
          Voucher opnieuw sturen
        </button>
      </div>
    </div>
    <p v-else-if="rit.bedrag_ontvangen <= 0 && rit.prijs > 0" class="text-sm text-red-500">
      Rit nog niets betaald dus kunnen we geen voucher geven.
    </p>
    <div v-else class="flex flex-col gap-3">
      <p v-if="rit.prijs > 0" class="text-sm">
        Nog geen voucher gegeven<br>Bedrag betaald: {{ rit.bedrag_ontvangen }} EUR.
      </p>
      <UIInput
        v-model="form.bedrag"
        type="number"
        label="Voucher bedrag"
        rows="4"
        class="w-full max-w-sm"
        placeholder="Bedrag ..."
      />
      <UIInput
        v-model="form.opmerking"
        textarea
        label="Voucher opmerking"
        rows="4"
        class="w-full max-w-sm"
        placeholder="Opmerking ..."
      />
      <button :disabled="api.loading.value" class="btn success inline-block" @click="handleOpslaan">
        Voucher activeren
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        voucher_gegeven: null,
        bedrag_ontvangen: 0,
        prijs: 0,
      }
    },
  },
})

const emit = defineEmits(['close', 'update:rit'])
const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const form = ref({
  rit_id: props.rit.id,
  bedrag: props.rit.bedrag_ontvangen,
  opmerking: '',
})

const api = useApi()

const handleOpslaan = () => {
  api('RIT_VOUCHER_GEVEN', form).then(({ data }) => {
    vModelRit.value.voucher_gegeven = data.voucher
    emit('close')
  })
}

const handleVerwijderen = () => {
  api('RIT_VOUCHER_VERWIJDEREN', form).then(() => {
    vModelRit.value.voucher_gegeven = null
    emit('close')
  })
}
</script>
