import { computed } from 'vue'

import store from '@/store'

export default computed(() => Object.freeze([
  {
    icon: 'fa-home',
    label: 'Home',
    to: '/',
  },
  {
    icon: 'fa-calendar',
    label: 'Kalender',
    to: '/ritten/kalender',
  },
  {
    icon: 'fa-calendar-minus',
    label: 'Onbevestigd',
    to: '/ritten/onbevestigd',
  },
  {
    icon: 'fa-calendar-alt',
    label: 'Nieuwe afspraak',
    to: '/afspraken/nieuw',
    classesLi: 'mt-4',
  },
  {
    icon: 'fa-plus-square',
    label: 'Nieuwe rit',
    to: '/ritten/nieuw',
  },
  {
    icon: 'fa-chart-pie',
    label: 'Dashboard',
    to: '/stats/dashboard',
    classesLi: 'mt-4',
  },
  {
    icon: 'fa-search-location',
    label: 'Tracking',
    to: '/opvolging/tracking',
  },
  {
    icon: 'fa-chart-bar',
    label: 'Statistieken',
    classesLi: 'mt-4',
    submenu: [
      {
        icon: 'fa-chart-pie',
        label: 'Dashboard',
        to: '/stats/dashboard',
      },
      {
        icon: 'fa-user-tie',
        label: 'Chauffeurs',
        to: '/stats/chauffeurs',
      },
      {
        icon: 'fa-chart-line',
        label: 'Ritten',
        to: '/stats/ritten',
      },
      {
        icon: 'fa-chart-line',
        label: 'Onderaanneming',
        to: '/stats/onderaanneming',
      },
      {
        icon: 'fa-donate',
        label: 'Kosten',
        to: '/stats/kosten',
      },
      {
        icon: 'fa-gift',
        label: 'Promoties',
        to: '/stats/promoties',
      },
      {
        icon: 'fa-car',
        label: 'Wagens',
        to: '/stats/wagens',
      },
      {
        icon: 'fa-hands-helping',
        label: 'Affiliates',
        to: '/stats/affiliates',
      },
      {
        icon: 'fa-star-half-alt',
        label: 'Enquetes',
        to: '/stats/enquetes',
      },
      {
        icon: 'fa-map-marked-alt',
        label: 'Heatmap',
        to: '/stats/heatmap',
      },
    ],
  },
  {
    icon: 'fa-user-tie',
    label: 'Chauffeur',
    submenu: [
      {
        icon: 'fa-calendar',
        label: 'Mijn planning',
        to: '/chauffeur/planning',
      },
      {
        icon: 'fa-wrench text-blue-500',
        label: 'Onderhoud aanvraag',
        to: '/chauffeur/onderhoud',
      },
      store.getters.beschikbaarhedenVia === 'afwezigheden' && {
        icon: 'fa-calendar-day text-red-500',
        label: 'Afwezigheid aanvraag',
        labelClass: 'text-xs',
        to: '/chauffeur/afwezigheid',
      },
      store.getters.beschikbaarhedenVia === 'aanwezigheden' && {
        icon: 'fa-calendar-day text-green-500',
        label: 'Aanwezigheid doorgeven',
        labelClass: 'text-xs',
        to: '/chauffeur/aanwezigheid',
      },
      {
        icon: 'fa-info-circle',
        label: 'Procedures',
        to: '/chauffeur/procedures',
      },
      {
        icon: 'fa-book-reader',
        label: 'Arbeidsreglement',
        // to: '/chauffeur/arbeidsregelement',
        to: '/chauffeur/arbeidsregelement',
      },
      {
        icon: 'fa-id-card',
        label: 'Bestuurderspas',
        to: '/chauffeur/bestuurderspas',
      },
      {
        icon: 'fa-id-card',
        label: 'Rijbewijs',
        to: '/chauffeur/rijbewijs',
      },
      {
        icon: 'fa-star text-yellow-300',
        label: 'Drivers Champions League',
        labelClass: 'text-xs text-yellow-200 font-bold scale-90',
        to: '/chauffeur/reward',
      },
    ].filter(Boolean),
  },
  {
    icon: 'fa-calendar',
    label: 'Ritten',
    submenu: [
      {
        icon: 'fa-calendar',
        label: 'Kalender',
        to: '/ritten/kalender',
      },
      {
        icon: 'fa-calendar-minus',
        label: 'Onbevestigd',
        to: '/ritten/onbevestigd',
      },
      {
        icon: 'fa-file-invoice',
        label: 'Offerte sturen',
        to: '/offerte/nieuw',
      },
      {
        icon: 'fa-plus-square',
        label: 'Nieuwe rit',
        to: '/ritten/nieuw',
      },
    ],
  },
  {
    icon: 'fa-list-alt',
    label: 'Opvolging',
    submenu: [
      {
        icon: 'fa-list-alt',
        label: 'Boekingen',
        to: '/opvolging/boekingen?type=ALLES',
      },
      {
        icon: 'fa-file-invoice',
        label: 'Offertes opvolgen',
        to: '/opvolging/boekingen?type=OFFERTES',
      },
      {
        icon: 'fa-list-alt',
        label: 'Onbetaald',
        to: '/opvolging/boekingen?type=ONBETAALD',
      },
      {
        icon: 'fa-search-location',
        label: 'Tracking',
        to: '/opvolging/tracking',
      },
      {
        icon: 'fa-comment-dots',
        label: 'Feedback',
        to: '/opvolging/feedback',
      },
      {
        icon: 'fa-book',
        label: 'Gastenboek',
        to: '/opvolging/gastenboek',
      },
      {
        icon: 'fa-star-half-alt',
        label: 'Enquetes',
        to: '/opvolging/Enquetes',
      },
      {
        icon: 'fa-envelope',
        label: 'Mails',
        to: '/opvolging/mails',
      },
    ],
  },
  {
    icon: 'fa-swatchbook',
    label: 'Administratie',
    submenu: [
      {
        icon: 'fa-book-open',
        label: 'Kasboek',
        to: '/administratie/kasboek',
      },
      {
        icon: 'fa-book-reader',
        label: 'Afrekenen',
        to: '/administratie/afrekenen',
      },
      {
        icon: 'fa-wallet',
        label: 'Cash in omloop',
        to: '/administratie/cash-in-omloop',
      },
      {
        icon: 'fa-money-bill-alt',
        label: 'Betalingen',
        to: '/administratie/betalingen',
      },
      {
        icon: 'fa-donate',
        label: 'Kosten',
        to: '/administratie/kosten',
      },
      {
        icon: 'fa-user-clock',
        label: 'Shiften',
        to: '/administratie/shiften',
      },
      {
        icon: 'fa-calendar-week',
        label: 'Prestaties',
        to: '/administratie/prestaties',
      },
      {
        icon: 'fa-user-plus text-green-500',
        label: 'Aanwezigheden',
        to: '/administratie/aanwezigheden',
      },
      {
        icon: 'fa-user-minus text-red-500',
        label: 'Afwezigheden',
        to: '/administratie/afwezigheden',
      },
      {
        icon: 'fa-gift',
        label: 'Actieve vouchers',
        to: '/administratie/vouchers',
      },
    ],
  },
  {
    icon: 'fa-file-alt',
    label: 'Facturatie',
    submenu: [
      {
        icon: 'fa-file-invoice-dollar',
        label: 'Facturen',
        to: '/facturatie/facturen/overzicht',
      },
      {
        icon: 'fa-file-excel',
        label: 'Creditnota\'s',
        to: '/facturatie/creditnotas/overzicht',
      },
      {
        icon: 'fa-user-tag',
        label: 'Passagier saldo',
        to: '/facturatie/passagier-saldo',
      },
      {
        icon: 'fa-object-group',
        label: 'Gegevens Duplicaten',
        to: '/duplicaten/facturaties/uuid',
      },
      {
        icon: 'fa-piggy-bank',
        label: 'Bankrekeningen',
        to: '/facturatie/nordigen',
      },
      {
        icon: 'fa-list-alt',
        label: 'Controle',
        to: '/facturatie/facturen/controle',
      },
    ],
  },
  {
    icon: 'fa-cogs',
    label: 'Platform',
    submenu: [
      {
        icon: 'fa-bell',
        label: 'Notificaties',
        to: '/platform/notificaties/nieuw',
      },
      {
        icon: 'fa-eye',
        label: 'Dashboard views',
        to: '/platform/views',
      },
      {
        icon: 'fa-newspaper',
        label: 'Nieuwsartikels',
        to: '/platform/nieuwsartikels',
      },
      {
        icon: 'fa-table',
        label: 'Werkdagen',
        to: '/platform/werkdagen',
      },
      {
        icon: 'fa-user-plus',
        label: 'Gebruikers (Actief)',
        to: '/platform/users',
      },
      {
        icon: 'fa-user-minus text-gray-500',
        label: 'Gebruikers (Inactief)',
        to: '/platform/users/inactief',
      },
      {
        icon: 'fa-car',
        label: 'Wagens (Actief)',
        to: '/platform/wagens',
      },
      {
        icon: 'fa-car text-gray-500',
        label: 'Wagens (Inactief)',
        to: '/platform/wagens/inactief',
      },
      {
        icon: 'fa-user-slash',
        label: 'Blacklists / Whitelists',
        to: '/platform/blacklists',
      },
      {
        icon: 'fa-hands-helping',
        label: 'Affiliates',
        to: '/platform/affiliates',
      },
      {
        icon: 'fa-universal-access',
        label: 'VIPs',
        to: '/platform/vips',
      },
    ],
  },

  // Account
  {
    icon: 'fa-user',
    label: 'Profiel',
    to: '/account/profiel',
  },
  {
    icon: 'fa-gift',
    label: 'Vouchers/Waardebonnen',
    to: '/account/vouchers',
  },
  {
    icon: 'fa-book',
    label: 'Gastenboek',
    to: '/account/gastenboek',
  },
  {
    icon: 'fa-hands-helping',
    label: 'Affiliate programma',
    to: '/account/affiliate',
  },
]))
