<template>
  <div class="flex flex-col gap-3">
    <h3 v-if="message?.length">
      <span v-for="part in String(message).split('\n')" :key="part" class="block">{{ part }}&nbsp;</span>
    </h3>
    <p v-if="subtext?.length">
      <span v-for="part in String(subtext).split('\n')" :key="part" class="block">{{ part }}&nbsp;</span>
    </p>
    <button class="btn success inline-block" :disabled="disableTime > 0" @click="handleSubmit">
      <span v-if="disableTime > 0" :key="disableTime">Kijk even na ... ({{ disableTime }} s.)</span>
      <span v-else>{{ textYes }}</span>
    </button>
    <button v-if="textNo" class="btn success inline-block" @click="handleCancel">
      {{ textNo }}
    </button>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps({
  message: String,
  subtext: String,
  delay: Number,
  textYes: {
    type: String,
    default: 'Ja, bevestig',
  },
  textNo: {
    type: String,
  },
  callback: {
    type: Function,
    required: true,
  },
})
const emit = defineEmits(['close'])
const disableTime = ref(Math.round(Math.max(props.delay, 0)))
let interval = null
onMounted(() => {
  if (disableTime.value > 0) {
    interval = setInterval(() => {
      disableTime.value -= 1
      if (disableTime.value <= 0) {
        clearInterval(interval)
      }
    }, 1000)
  }
})
onBeforeUnmount(() => {
  clearInterval(interval)
})

const handleCancel = () => {
  emit('close', true)
}

const handleSubmit = () => {
  if (typeof props.callback !== 'function') throw new Error('Missing callback')
  props.callback()
  emit('close', true)
}
</script>
