<template>
  <div v-if="rit.id > 0" class="flex flex-col gap-4">
    <div>
      <UIInput
        v-model="form.value"
        textarea
        label="Opmerking"
        rows="4"
        class="w-full mb-0 pb-0"
        placeholder="Opmerking ..."
      />
    </div>
    <button :disabled="api.loading.value" class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        opmerking: null,
      }
    },
  },
})

const emit = defineEmits(['close', 'update:rit'])
const rit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const form = ref({
  id: props.rit.id,
  property: 'opmerking',
  value: props.rit.opmerking,
})

const api = useApi()

const handleOpslaan = () => {
  api('RIT_PROP_VALUE', form).then(() => {
    rit.value.opmerking = form.value.value
    emit('close')
  })
}
</script>
