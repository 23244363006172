<!-- ErrorBoundary.vue -->
<template>
  <div v-if="showError" class="border border-dashed bg-red-200 border-red-400 p-4 rounded text-red-700 text-lg">
    <h2 class="text-red-900">
      Error
    </h2>
    {{ error }}
    {{ errorMessage }}
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      errorMessage: '',
    }
  },
  computed: {
    showError() {
      if (!this.error) return false
      const isAxiosError = (this.error && this.error.name === 'AxiosError')
      if (isAxiosError) return false
      return true
    },
  },
  errorCaptured(error, vm, info) {
    this.error = error
    this.errorMessage = info
    return false // Prevents the error from propagating further
  },
}
</script>
