import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default () => {
  const store = useStore()
  const router = useRouter()

  return () => store.dispatch('API_LOGOUT').then(() => {
    router.push('/login')
  })
}
