<template>
  <UISelectModalNullable
    v-bind="$attrs"
    v-model="innerValue"
    :label="label === false ? '' : (typeof label === 'string' ? label : 'Wagen')"
    :options="options"
    :format-label="formatLabelWagens"
    :placeholder="placeholder"
    search-placeholder="Wagen zoeken..."
  />
</template>

<script setup>
import { computed, defineEmits, defineProps, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectModalNullable from '@/components/UI/Select/SelectModalNullable.vue'

import { isThisNaN } from '@/functions/isThisNaN'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const props = defineProps({
  label: [Boolean, String],
  formatLabel: Function,
  useQuery: Boolean,
  skipMounted: Boolean,
  active: {
    type: Boolean,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: 'Selecteer een wagen',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])
const store = useStore()
const formatLabelWagens = inject('formatLabelWagens', undefined) || props.formatLabel

const options = computed(() => {
  const id = Number(props.modelValue || -1)
  const array = clone(store.state.wagens || [])
  if (typeof props.active !== 'undefined') {
    return array.filter(item => item.is_active === !!props.active || item.id === id)
  }
  return array
})

const innerValue = props.useQuery ? useRouteQueryInt('wagen', null) : ref(props.modelValue || null)
if (props.modelValue > 0) {
  innerValue.value = Number(props.modelValue)
}
if (!innerValue.value || innerValue.value <= 0 || isThisNaN(Number(innerValue.value))) {
  innerValue.value = null
}
const emitValue = val => emit('update:modelValue', val)
watch(() => innerValue.value, (val, oldval) => {
  if (val === oldval) return
  emitValue(val)
})
if (!props.skipMounted) emitValue(innerValue.value)
</script>
