import { watch } from 'vue'

import useWebsocket from '@/hooks/useWebsocket'

const ENABLE_HEALTH_WEBSOCKET = false

export default handler => {
  const { connected, connectedOnce } = useWebsocket(process.env.VUE_APP_WEBSOCKET, { reconnectTime: 5000 })

  watch(() => connected.value, isHealty => {
    if (ENABLE_HEALTH_WEBSOCKET && connectedOnce.value) {
      handler(isHealty)
    }
  })

  return { connectedOnce, connected }
}
