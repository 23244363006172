import { createStore } from 'vuex'

import { axiosClient as axios } from '@/plugins/axios'

import { capitalize } from '@/functions/formatText'
import isDashboard from '@/functions/isDashboard'
import useApi from '@/hooks/useApi'
import useWindow from '@/hooks/useWindow'

const store = createStore({
  state: {
    profile: null,

    bedrijf_themas: {},
    chauffeurs: [],
    wagens: [],

    options_kosten_locaties: [],
    // options_kosten_types: [],
    // options_persona_titles: [],
    // options_persona_categories: [],
    // options_afwezigheid_types: [],

  },
  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = profile ? { ...profile } : null
    },

    SET_BEDRIJF_THEMAS(state, themas) {
      state.bedrijf_themas = themas
    },
    SET_OPTIONS_CHAUFFEURS(state, options) {
      state.chauffeurs = options.map(item => ({ ...item, label: capitalize(item.label) }))
    },
    SET_OPTIONS_WAGENS(state, options) {
      state.wagens = options
    },
    SET_CUSTOM_OPTIONS(state, { table, options }) {
      state[table] = options
    },
  },
  getters: {
    beschikbaarhedenVia(state) {
      return state.profile?.tabel_beschikbaarheden || 'afwezigheden'
    },
    isWazePreferred(state) {
      return state.profile?.navigatie_app === 'WAZE'
    },
    isExpoAppWebview() {
      if (!useWindow()) return false
      return !!window.ExpoData?.emit
    },
    role(state) {
      return String(state.profile?.role || '').toUpperCase()
    },
    currentUserId(state) {
      return state.profile.id
    },
    currentUserName(state) {
      return [state.profile?.firstname || '', state.profile?.lastname || ''].join(' ').trim()
    },
    isAuthenticated(state) {
      return state.profile && state.profile.id > 0 && !!store.getters.role
    },
    isMasterPermission() {
      const allowed = ['DEBUG', 'MASTER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    isAdminPermission() {
      const allowed = ['ADMIN']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    isKantoorPermission() {
      const allowed = ['KANTOOR']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    isChauffeurPermission() {
      const allowed = ['CHAUFFEUR', 'ONDERAANNEMER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    // has
    hasMasterPermission() {
      const allowed = ['DEBUG', 'MASTER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasAdminPermission() {
      const allowed = ['DEBUG', 'MASTER', 'ADMIN']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasKantoorPermission() {
      const allowed = ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasChauffeurOrOnderaannemerPermission() {
      const allowed = ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR', 'CHAUFFEUR', 'ONDERAANNEMER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasChauffeurWithoutOnderaannemerPermission() {
      const allowed = ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR', 'CHAUFFEUR']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasOnderaannemerPermission() {
      const allowed = ['ONDERAANNEMER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasKlantPermission() {
      const allowed = ['KLANT']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    hasBoekhouderPermission() {
      const allowed = ['BOEKHOUDER']
      return !!(store.getters.isAuthenticated && allowed.includes(store.getters.role))
    },
    actieveChauffeurs(state) {
      return state.chauffeurs.filter(item => item.is_active)
    },
    actieveOnderaannemers() {
      return store.getters.actieveChauffeurs.filter(item => item?.label?.startsWith('[OA]'))
    },
    chauffeur(state) {
      return _id => {
        const id = Number(_id)
        return state.chauffeurs.find(el => Number(el.id || el.value) === id)
      }
    },
    wagen(state) {
      return _id => {
        const id = Number(_id)
        return state.wagens.find(el => Number(el.id || el.value) === id)
      }
    },
  },
  actions: {
    async CLIENT_INIT({ dispatch }) {
      const profile = await dispatch('API_PROFILE_SYNC')
      await dispatch('SYNC_AFTER_LOGIN', profile)
      return { profile, isAuthenticated: store.getters.isAuthenticated }
    },
    async SYNC_AFTER_LOGIN({ dispatch }) {
      if (!store.getters.isAuthenticated) return

      const promises = []

      if (store.getters.hasKantoorPermission) {
        promises.push(dispatch('API_BEDRIJF_THEMAS_SYNC'))
        promises.push(dispatch('API_CHAUFFEURS_SYNC'))
        promises.push(dispatch('API_WAGENS_SYNC'))
      } else if (store.getters.hasChauffeurWithoutOnderaannemerPermission) {
        promises.push(dispatch('API_BEDRIJF_THEMAS_SYNC'))
        promises.push(dispatch('API_WAGENS_SYNC'))
      }

      await Promise.all(promises)

      if (store.getters.isExpoAppWebview) {
        window.ExpoData.emit('login', { user_id: store.getters.currentUserId, role: store.getters.role })
      }
    },
    async API_PROFILE_SYNC({ commit }) {
      return await axios.get('/api/shared/auth/profile').then(({ data }) => {
        commit('SET_PROFILE', data.profile)
        return data.profile
      })
    },
    async API_BEDRIJF_THEMAS_SYNC({ commit }) {
      return await axios.get('/api/dashboard/platform/data/bedrijf_thema').then(({ data }) => {
        commit('SET_BEDRIJF_THEMAS', data)
        return data
      })
    },
    async API_CHAUFFEURS_SYNC({ commit }) {
      return await axios.get('/api/dashboard/platform/options/chauffeurs').then(({ data }) => {
        commit('SET_OPTIONS_CHAUFFEURS', data.options)
        return data.options
      })
    },
    async API_WAGENS_SYNC({ commit }) {
      return await axios.get('/api/dashboard/platform/options/wagens').then(({ data }) => {
        commit('SET_OPTIONS_WAGENS', data.options)
        return data.options
      })
    },
    async API_CUSTOM_OPTIONS_SYNC({ commit }, { table }) {
      if (store.state[table]?.length) return
      const api = useApi()
      return await api('CUSTOM_OPTIONS_LIST', { params: { table } }).then(({ data }) => {
        commit('SET_CUSTOM_OPTIONS', { options: data.options, table })
        return data.options
      })
    },
    async API_CUSTOM_OPTIONS_EDIT({ commit }, { table, id, value, label }) {
      const option = { id, label, value }
      const api = useApi()

      return await api('CUSTOM_OPTIONS_EDIT', { ...option, table }).then(() => {
        const options = [...store.state[table]].filter(el => el.id !== id && el.value !== value)
        options.push(option)
        commit('SET_CUSTOM_OPTIONS', { options, table })
      })
    },
    async API_CUSTOM_OPTIONS_CREATE({ commit }, { table, value, label }) {
      const option = { label, value }
      const api = useApi()

      return await api('CUSTOM_OPTIONS_CREATE', { ...option, table }).then(() => {
        const options = [...store.state[table]].filter(el => el.value !== value)
        options.push(option)
        commit('SET_CUSTOM_OPTIONS', { options, table })
      })
    },
    async API_LOGIN({ dispatch, commit }, { email, password }) {
      return axios
        .post('/api/shared/auth/login', {
          email,
          password,
          dashboard: isDashboard(),
        })
        .then(async ({ data }) => {
          commit('SET_PROFILE', data.profile)
          await dispatch('SYNC_AFTER_LOGIN', data.profile)
          return data.profile
        })
    },
    async API_LOGOUT({ commit }) {
      return axios
        .post('/api/shared/auth/logout', { dashboard: isDashboard() })
        .then(async () => {
          commit('SET_PROFILE', null)
          return true
        })
    },
  },
  modules: {},
})

export default store
