<template>
  <aside v-bind="$attrs" class="z-sidebar" :class="{ opened: modelValue }">
    <div v-once class="mb-5 text-center">
      <img src="/cdn/img/logo/logo-512.png" alt="" class="w-10 lg:w-12 inline -mt-1">
      <p class="text-white text-sm px-1 align-middle inline">
        Luchthavenvervoer Kevin
      </p>
    </div>
    <UISidebarMenu :menu="menuFiltered" @navigation="onNavigation" />
    <small class="block py-4 text-center text-gray-400">Build: {{ isDashboard() ? 'D' : 'K' }}-{{ buildVersion }}</small>
  </aside>
  <div
    v-show="modelValue"
    class="z-sidebar-overlay lg:hidden overlay"
    aria-hidden="true"
    @click.self.stop="$emit('update:modelValue', false)"
  ></div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import clone from 'just-clone'

import UISidebarMenu from '@/components/UI/Sidebar/Menu.vue'

import NAVIGATION_MENU from '@/constants/NAVIGATION_MENU'
import { hasRoutePathPermission } from '@/functions/hasRoutePermission'
import isDashboard from '@/functions/isDashboard'

defineProps({
  modelValue: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const buildVersion = String(process.env.VUE_APP_BUILD_VERSION || '').trim()

const menuFiltered = computed(() => {
  const array = clone(NAVIGATION_MENU.value).map(el => {
    const item = clone(el)
    if (item.submenu?.length) {
      item.submenu = item.submenu.filter(sub => sub.to && hasRoutePathPermission(sub.to))
      if (!item.submenu?.length) return null
    }
    if (item.to && !hasRoutePathPermission(item.to)) return null
    return item
  }).filter(el => !!el)

  return Object.freeze(array)
})

const onNavigation = () => {
  if (window.innerWidth < 1024) {
    emit('update:modelValue', false)
  }
}
</script>

<style lang="scss">
aside {
  background-color: #202935;
  // border-right: 1px solid #fefefe;
  @apply w-64 absolute left-0 top-0 transform h-screen overflow-y-scroll flex-shrink-0 transition-transform duration-200 ease-in-out -translate-x-64 py-4;

  &.opened {
    @apply lg:static left-auto top-auto translate-x-0 overflow-y-auto;
  }
}
</style>
