<template>
  <div v-if="!store.getters.hasMasterPermission">
    <small class="text-red-500 font-bold">Geen rechten om betalingen aan te passen</small>
  </div>
  <div v-else class="flex flex-col gap-3">
    <UISelectChauffeur
      v-model="betaling.user_id"
      class="w-full max-w-sm"
      active
      label
    />
    <UISelectFixed
      v-model="betaling.methode"
      :disabled="!store.getters.hasKantoorPermission"
      type="BETAALMETHODE"
      class="w-full max-w-sm"
      label="Betaalmethode"
    />
    <UIInputDatePicker v-model:date="betaling.date" label="Betaal datum" class="text-left" />
    <UIInput
      v-model="betaling.bedrag"
      :disabled="betaling.date_terugbetaling"
      label="Bedrag betaald"
      type="number"
      class="w-full max-w-sm"
      placeholder="Bedrag ..."
    />
    <UIInput
      v-model="betaling.opmerking"
      textarea
      label="Opmerking over de ontvangen betaling"
      rows="4"
      class="w-full max-w-sm mb-2"
      placeholder="Eventuele opmerking ..."
    />

    <button v-if="!showTerugbetaling" class="inline-block text-left w-auto" @click.stop="showTerugbetaling = true">
      <UIInputLabel>Terugbetaling +</UIInputLabel>
    </button>
    <UIInputLabel v-else>
      Terugbetaling
    </UIInputLabel>
    <div v-if="showTerugbetaling" class="card boder-yellow-500 -mt-2 mb-4">
      <UIInput
        v-model="betaling.bedrag_terugbetaald"
        label="Bedrag terugbetaald"
        type="number"
        class="w-full max-w-sm"
        placeholder="Bedrag terugbetaling ..."
      />
      <UIInputDatePicker v-model:date="betaling.date_terugbetaling" label="Terugbetaling datum" class="text-left" />
    </div>

    <div v-if="betaling.methode !== 'CASH' && store.getters.isChauffeurPermission">
      <p class="text-red-500">
        Als chauffeur dien je enkel CASH betalingen door te geven.
        <br>
        Indien ONLINE of OVERSCHRIJVING, zal het dashboard automatisch de betaling registreren.
        <br>
      </p>
    </div>

    <button class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

import { dateString, isDate } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: {
    type: Function,
    required: true,
  },
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        user_id: null,
        date: null,
        date_terugbetaling: null,
        bedrag: null,
        bedrag_terugbetaald: null,
        methode: null,
        opmerking: null,
      }
    },
  },
})

const emit = defineEmits(['close'])
const store = useStore()
const vModel = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const showTerugbetaling = ref(!!(vModel.value.bedrag_terugbetaald || vModel.value.date_terugbetaling))

const betaling = ref({
  id: vModel.value.id,
  user_id: vModel.value.user_id,
  date: dateString(vModel.value.date && isDate(vModel.value.date) ? new Date(vModel.value.date) : new Date()),
  date_terugbetaling: vModel.value.date_terugbetaling ? dateString(vModel.value.date_terugbetaling) : null,
  bedrag: Math.max(0, vModel.value.bedrag),
  bedrag_terugbetaald: Math.max(0, vModel.value.bedrag_terugbetaald),
  methode: vModel.value.methode || 'CASH',
  opmerking: vModel.value.opmerking || '',
})

const api = useApi()
const handleOpslaan = () => {
  if (!store.getters.hasKantoorPermission) {
    betaling.value.methode = 'CASH'
  }
  api('BETALING_EDIT', betaling).then(() => {
    Object.entries(betaling.value).forEach(([key, value]) => {
      vModel.value[key] = value
    })
    emit('close')
    if (typeof props.callback === 'function') {
      props.callback()
    }
  })
}
</script>
