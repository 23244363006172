<template>
  <div :key="isLatestVersionInner">
    <p v-if="!isLatestVersionInner" class="w-full overflow-hidden text-center bg-green-400 font-bold text-white text-sm py-1">
      Nieuwere versie van het dashboard beschikbaar.
      <button aria-label="refresh page" class="text-blue-600" @click="toggleFullReload">
        Nu vernieuwen
      </button>
    </p>
  </div>
</template>

<script setup>
import { computed, onMounted, toValue } from 'vue'

import useCheckLatestVersion, { getCurrentVersion } from '@/hooks/useCheckLatestVersion'
import useNamedInterval from '@/hooks/useNamedInterval'
import useWindowCacheBust from '@/hooks/useWindowCacheBust'
import useWindowCacheRefresh from '@/hooks/useWindowCacheRefresh'

const { isLatestVersion } = useCheckLatestVersion()
onMounted(() => getCurrentVersion())
const isLatestVersionInner = computed(() => !!toValue(isLatestVersion))

const refresh = useWindowCacheRefresh()
const toggleFullReload = () => useWindowCacheBust(refresh)
useNamedInterval('getCurrentVersion', getCurrentVersion, 3600000)
</script>
