import FormAffiliate from '@/components/Form/Modal/Affiliate.vue'
import FormBeschikbaarheid from '@/components/Form/Modal/Beschikbaarheid.vue'
import FormBetaling from '@/components/Form/Modal/Betaling.vue'
import FormBetalingFinancial from '@/components/Form/Modal/BetalingFinancial.vue'
import FormBevestigActie from '@/components/Form/Modal/BevestigActie.vue'
import FormBlacklistWhitelist from '@/components/Form/Modal/BlacklistWhitelist.vue'
import FormCallsList from '@/components/Form/Modal/Calls.vue'
import FormFactuurMailen from '@/components/Form/Modal/FactuurMailen.vue'
import FormFontSize from '@/components/Form/Modal/FontSize.vue'
import FormGastenboek from '@/components/Form/Modal/Gastenboek.vue'
import FormKilometerstand from '@/components/Form/Modal/Kilometerstand.vue'
import FormKost from '@/components/Form/Modal/Kost.vue'
import FormLongPagination from '@/components/Form/Modal/LongPagination.vue'
import FormNordigenRequisition from '@/components/Form/Modal/NordigenRequisition.vue'
import FormRitAnnuleer from '@/components/Form/Modal/RitAnnuleer.vue'
import FormRitBetaling from '@/components/Form/Modal/RitBetaling.vue'
import FormRitBevestig from '@/components/Form/Modal/RitBevestig.vue'
import FormRitExternalReview from '@/components/Form/Modal/RitExternalReview.vue'
import FormRitFactuur from '@/components/Form/Modal/RitFactuur.vue'
import FormRitFeedback from '@/components/Form/Modal/RitFeedback.vue'
import FormRitOnderaanneming from '@/components/Form/Modal/RitOnderaanneming.vue'
import FromRitOpmerking from '@/components/Form/Modal/RitOpmerking.vue'
import FormRitPassagiers from '@/components/Form/Modal/RitPassagiers.vue'
import FormRitPlanningOpties from '@/components/Form/Modal/RitPlanningOpties.vue'
import FormRitVerwijderen from '@/components/Form/Modal/RitVerwijderen.vue'
import FormRitVoucher from '@/components/Form/Modal/RitVoucher.vue'
import FormShift from '@/components/Form/Modal/Shift.vue'
import FormTerugritAanvragen from '@/components/Form/Modal/TerugritAanvragen.vue'
import FormToekenning from '@/components/Form/Modal/Toekenning.vue'
import FormUser from '@/components/Form/Modal/User.vue'
import FormWagen from '@/components/Form/Modal/Wagen.vue'

export const DIALOG_COMPONENTS = {
  FORM_WHITELIST_BLACKLIST: FormBlacklistWhitelist,
  CALLS_LIST: FormCallsList,
  FORM_USER: FormUser,
  FORM_WAGEN: FormWagen,
  FORM_SHIFT: FormShift,
  FORM_KOST: FormKost,
  FORM_BETALING_FINANCIAL: FormBetalingFinancial,
  FORM_BETALING: FormBetaling,
  FORM_AFWEZIGHEID: FormBeschikbaarheid,
  FORM_AANWEZIGHEID: FormBeschikbaarheid,
  FACTUUR_MAILEN: FormFactuurMailen,
  FORM_GASTENBOEK: FormGastenboek,
  FORM_KILOMETERSTAND: FormKilometerstand,
  FORM_RIT_BETALING: FormRitBetaling,
  FORM_RIT_FEEDBACK: FormRitFeedback,
  FORM_RIT_BEVESTIG: FormRitBevestig,
  FORM_RIT_ANNULEER: FormRitAnnuleer,
  FORM_RIT_FACTUUR: FormRitFactuur,
  FORM_RIT_VOUCHER: FormRitVoucher,
  FORM_RIT_EXTERNAL_REVIEW: FormRitExternalReview,
  FORM_RIT_PLANNING_OPTIE: FormRitPlanningOpties,
  FORM_RIT_OPMERKING: FromRitOpmerking,
  FORM_RIT_ONDERAANNEMING: FormRitOnderaanneming,
  FORM_RIT_FONT_SIZE: FormFontSize,
  BEVESTIG_ACTIE: FormBevestigActie,
  FORM_RIT_X: FormRitVerwijderen,
  FORM_RIT_PASSAGIERS: FormRitPassagiers,
  FORM_AFFILIATE: FormAffiliate,
  FORM_LONG_PAGINATION: FormLongPagination,
  FORM_NORDIGEN_REQUISITION: FormNordigenRequisition,
  FORM_TOEKENNING: FormToekenning,
  FORM_TERUGRIT_AANVRAGEN: FormTerugritAanvragen,
}

export const DIALOG_TITLES = {
  FORM_WHITELIST_BLACKLIST: 'Whitelist / Blacklist',
  CALLS_LIST: 'Gesprekken',
  FORM_USER: 'Gebruiker',
  FORM_WAGEN: 'Wagen',
  FORM_SHIFT: 'Shift uren',
  FORM_KOST: 'Kost',
  FORM_BETALING_FINANCIAL: 'Betaling factuur',
  FORM_BETALING: 'Betaling',
  FORM_AFWEZIGHEID: 'Afwezigheid',
  FORM_AANWEZIGHEID: 'Aanwezigheid',
  FACTUUR_MAILEN: 'Factuur mailen',
  FORM_GASTENBOEK: 'Gastenboek entry',
  FORM_KILOMETERSTAND: 'Kilometerstand',
  FORM_RIT_BETALING: 'Rit Betaling',
  FORM_RIT_FEEDBACK: 'Rit feedback',
  FORM_RIT_BEVESTIG: 'Bevestig rit',
  FORM_RIT_ANNULEER: 'Annuleer rit',
  FORM_RIT_FACTUUR: 'Rit factuur',
  FORM_RIT_VOUCHER: 'Rit voucher geven',
  FORM_RIT_EXTERNAL_REVIEW: 'Externe review',
  FORM_RIT_PLANNING_OPTIE: 'Rit interne memo',
  FORM_RIT_OPMERKING: 'Rit opmerking',
  FORM_RIT_ONDERAANNEMING: 'Onderaannemers verwittigen',
  FORM_RIT_FONT_SIZE: 'Lettertypegrootte',
  BEVESTIG_ACTIE: 'Bevestig actie',
  FORM_RIT_X: 'Rit verwijderen',
  FORM_RIT_PASSAGIERS: 'Passagiers checklist',
  FORM_AFFILIATE: 'Affiliate',
  FORM_LONG_PAGINATION: 'Pagina selecteren',
  FORM_NORDIGEN_REQUISITION: 'Nordigen bankrekening toevoegen',
  FORM_TOEKENNING: 'Toekenning aanpassen',
  FORM_TERUGRIT_AANVRAGEN: 'Terugrit aanvragen',
}

export const DIALOG_PROPS = {
  FORM_AFWEZIGHEID: { tabel: 'afwezigheden' },
  FORM_AANWEZIGHEID: { tabel: 'aanwezigheden' },
}

export default {
  DIALOG_COMPONENTS,
  DIALOG_TITLES,
  DIALOG_PROPS,
}
