<template>
  <div>
    <UIInputLabel
      v-if="label"
      :id="id"
      class="block w-full"
      :required="required"
      :focussed="focussed"
      @click.prevent
    >
      {{ label }}
    </UIInputLabel>

    <div class="inline-flex items-center gap-4">
      <button
        v-if="buttons && !timepicker && !hideDatepicker"
        :disabled="disabled"
        class="btn small icon hover:bg-gray-600 bg-dashboard-dark text-white text-sm hover:text-base"
        @click="addDays(-1)"
      >
        <i class="fas fa-arrow-left"></i>
      </button>

      <div v-if="!hideDatepicker" class="datepicker">
        <input
          :id="id"
          :ref="(el) => (inputDatepickerRef = el)"
          v-bind="$attrs"
          v-model="dateValue"
          :label="label"
          :disabled="disabled"
          type="text"
          placeholder="YYYY-MM-DD"
          class="input cursor-pointer w-40 pr-2"
          :class="{ 'text-center': !$attrs?.class?.includes('text-') }"
          :min="minDate ? dateString(resetTime(minDate)) : undefined"
          @input="dateValue = $event?.target?.value || ''"
          @focus.prevent.stop="focussed = true"
          @blur.prevent.stop="focussed = false"
          @keypress.prevent.stop
          @keyup.prevent.stop
          @keydown.prevent.stop
        >
        <div v-if="clearable" class="inline-block mx-1">
          <button v-if="dateValue" class="small" @click="dateValue = null">
            <i class="fas fa-times text-red-500"></i>
          </button>
        </div>
      </div>

      <button
        v-if="buttons && !timepicker && !hideDatepicker"
        :disabled="disabled"
        class="btn small icon hover:bg-gray-600 bg-dashboard-dark text-white text-sm hover:text-base"
        @click="addDays(1)"
      >
        <i class="fas fa-arrow-right"></i>
      </button>

      <div v-if="timepicker" class="timepicker">
        <input
          :ref="(el) => (inputTimepickerRef = el)"
          v-model="timeValue"
          :disabled="disabled"
          type="text"
          class="input timepicker"
          placeholder="HH:MM"
          style="width: 6rem"
          @input="timeValue = $event?.target?.value || ''"
          @focus.prevent.stop="focussed = true"
          @blur.prevent.stop="focussed = false"
          @keypress.prevent.stop
          @keyup.prevent.stop
          @keydown.prevent.stop
        >
        <div v-if="clearable" class="inline-block mx-1">
          <button v-if="timeValue" class="small" @click="timeValue = null">
            <i class="fas fa-times text-red-500"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import UIInputLabel from '@/components/UI/Input/Label.vue'

// import Datepicker from '@themesberg/tailwind-datepicker/js/Datepicker'
import { dateString, resetTime, timeString } from '@/functions/formatDate'

const props = defineProps({
  id: {
    type: [Number, String],
    default: uuidv4,
  },
  label: String,
  buttons: Boolean,
  timepicker: Boolean,
  hideDatepicker: Boolean,
  is12h: Boolean,
  required: Boolean,
  clearable: Boolean,
  disabled: Boolean,
  date: [Date, String, Number],
  minDate: [Date, String, Number],
  time: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:date', 'update:time'])

const stopPropagation = ev => ev?.stopPropagation && ev.stopPropagation()

const inputDatepickerRef = ref(null)
const inputTimepickerRef = ref(null)

const minDateValue = computed(() => props.minDate ? dateString(resetTime(props.minDate)) : undefined)
const dateValue = ref(props.date ? dateString(props.date || Date.now()) : null)
const timeValue = ref(
  props.time || props.time === 0 ? timeString(props.time || Date.now()) : null,
)

if (minDateValue.value && dateValue.value <= minDateValue.value) {
  dateValue.value = minDateValue.value
}

let _timeout = null
const setDate = async (val, wait) => {
  dateValue.value = val
  clearTimeout(_timeout)
  if (val !== props.date) {
    if (wait > 0) {
      _timeout = setTimeout(() => {
        emit('update:date', val)
      }, wait)
    } else {
      emit('update:date', val)
    }
  }
}

const instanceDatepicker = ref(null)
const instanceTimepicker = ref(null)
// const isOpenDate = computed(() => !!instanceDatepicker.value?.isOpen)
// const isOpenTime = computed(() => !!instanceTimepicker.value?.isOpen)

const destoryAll = () => {
  try {
    if (instanceDatepicker.value?.close) instanceDatepicker.value.close()
    if (instanceDatepicker.value?.destroySelects)
      instanceDatepicker.value.destroySelects()
    if (instanceDatepicker.value?.destroy) instanceDatepicker.value.destroy()
  } catch {
    // Nothing
  } finally {
    instanceDatepicker.value = null
  }

  try {
    if (instanceTimepicker.value?.close) instanceTimepicker.value.close()
    if (instanceTimepicker.value?.destroySelects)
      instanceTimepicker.value.destroySelects()
    if (instanceTimepicker.value?.destroy) instanceTimepicker.value.destroy()
  } catch {
    // Nothing
  } finally {
    instanceTimepicker.value = null
  }

  // handleCustomModalDisplay('block')
}

const stopElements = val => {
  val?.calendarEl?.addEventListener('click', stopPropagation)
  val?.cancelBtn?.addEventListener('click', stopPropagation)
  val?.doneBtn?.addEventListener('click', stopPropagation)
  val?.dateTextEl?.addEventListener('click', stopPropagation)
  val?.modalEl?.addEventListener('click', stopPropagation)
}

const init = () => {
  destoryAll()

  instanceDatepicker.value = window.M.Datepicker.init(
    inputDatepickerRef.value,
    {
      autoClose: false,
      format: 'yyyy-mm-dd',
      firstDay: 1,
      showDaysInNextAndPreviousMonths: true,
      container: window.document.body,
      onOpen() {
        if (instanceDatepicker?.value?.modalEl?.focus) {
          instanceDatepicker.value.modalEl.focus()
        }
      },
      onClose() {
        if (instanceDatepicker.value?.date) {
          setDate(dateString(instanceDatepicker.value.date))
        }
      },
    },
  )
  stopElements(instanceDatepicker.value)

  if (!props.timepicker) return
  instanceTimepicker.value = window.M.Timepicker.init(
    inputTimepickerRef.value,
    {
      autoClose: false,
      format: 'HH:mm',
      firstDay: 1,
      showDaysInNextAndPreviousMonths: true,
      twelveHour: !!props.is12h,
      container: window.document.body,
      onOpenStart() {
        if (instanceTimepicker.value?.modalEl?.focus) {
          instanceTimepicker.value?.modalEl.focus()
        }
      },
      onCloseEnd() {
        if (instanceTimepicker.value?.time) {
          timeValue.value = timeString(instanceTimepicker.value.time)
        }
      },
    },
  )
  stopElements(instanceTimepicker.value)
}

onMounted(() => setImmediate(() => init()))
onBeforeUnmount(destoryAll)

watch(
  () => timeValue.value,
  val => {
    if (val !== props.time) emit('update:time', val)
  },
)
watch(
  () => props.date,
  (val, oldval) => {
    if (val === oldval || val === dateValue.value) return
    if (val && dateString(val) === dateValue.value) return
    setDate(val ? dateString(val) : null)
  },
)

const addDays = days => {
  const d = new Date(new Date(dateValue.value).getTime() + days * 86400000)
  setDate(dateString(d), 600)
}
const focussed = ref(false)
</script>
