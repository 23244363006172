import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

export const PROPS = {
  min: {
    type: [Number, String],
    default: 0,
  },
  max: {
    type: [Number, String],
    default: 0,
  },
  modelValue: {
    type: [Number, String],
    default: 0,
  },
}

export default (props, emit) => {
  const minInner = computed(() => Number.parseInt(props.min || 0, 10))
  const maxInner = computed(() => Number.parseInt(props.max || 0, 10))
  const safeValue = v => Math.min(maxInner.value, Math.max(minInner.value, Number(v)))

  const innerValue = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
  if (
    !props.prefixOptions?.find(el => el.value === props.modelValue)
    && !props.suffixOptions?.find(el => el.value === props.modelValue)
  ) {
    innerValue.value = safeValue(props.modelValue)
  }

  const increment = (amount = 1) => (innerValue.value = safeValue(innerValue.value + amount))

  return { minInner, maxInner, innerValue, increment }
}
