<template>
  <div class="flex flex-col gap-3">
    <UIInput
      v-model="form.feedback"
      textarea
      label="Feedback"
      rows="4"
      class="w-full max-w-sm"
      placeholder="Feedback ..."
    />

    <button class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  is_retour: {
    type: Boolean,
    required: true,
  },
  rit: {
    type: Object,
    default() {
      return {
        id: null,
      }
    },
  },
})
const emit = defineEmits(['close'])
const form = ref({
  rit_id: props.rit.id,
  is_retour: props.is_retour,
  feedback: '',
})

const api = useApi()
const handleOpslaan = () => {
  api('RIT_FEEDBACK_CREATE', form).then(() => {
    emit('close')
  })
}
</script>
