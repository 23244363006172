<template>
  <div v-if="rit?.is_retour">
    <p class="font-bold text-red-500 text-lg">
      Deze rit is heeft al een retourrit.
    </p>
  </div>
  <div v-else class="flex flex-col gap-3">
    <small class="text-gray-500 italic text-sm text-balance text-center">
      Je kan dit invullen en doorgeven indien de klant een terugrit in de wagen heeft aangevraagd.
    </small>

    <div class="flex flex-col gap-6 my-6">
      <div class="card flex flex-col gap-4">
        <InputDatePicker
          v-model:date="form.datum"
          v-model:time="form.tijd"
          required
          label="Datum en tijd"
          class="text-left"
          timepicker
        />
        <UIInput
          v-model="form.vluchtnummer"
          type="text"
          class="w-full max-w-sm"
          label="Vluchtnummer retourreis"
        />
        <div class="grid grid-cols-3 gap-2">
          <InputNumber
            v-model="form.personen"
            required
            hide-buttons
            label="Personen"
            :min="1"
            :max="50"
          />
          <InputNumber
            v-model="form.bagage"
            required
            hide-buttons
            label="Bagage"
            :min="0"
            :max="50"
          />
          <InputNumber
            v-model="form.handbagage"
            required
            hide-buttons
            label="Handbagage"
            :min="0"
            :max="50"
          />
        </div>
        <div class="grid grid-cols-3 gap-2">
          <InputNumber
            v-model="form.kinderstoelen"
            required
            hide-buttons
            label="Kinderstoelen"
            :min="0"
            :max="50"
          />
          <InputNumber
            v-model="form.kinderzitjes"
            required
            hide-buttons
            label="Kinderzitjes"
            :min="0"
            :max="50"
          />
          <InputNumber
            v-model="form.maxicosis"
            required
            hide-buttons
            label="Maxicosis"
            :min="0"
            :max="50"
          />
        </div>
      </div>

      <div class="card flex flex-col gap-4">
        <UICheckbox v-model="form.aanpassingen_adressen_nodig" label="Adressen aanpassingen nodig?" />
        <UIInput
          v-if="form.aanpassingen_adressen_nodig"
          v-model="form.aanpassingen_adressen"
          textarea
          rows="4"
          class="w-full max-w-sm"
          label="Beschrijf de adressen aanpassingen..."
        />
      </div>
    </div>

    <button class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

import UICheckbox from '@/components/UI/Input/Checkbox'
import InputDatePicker from '@/components/UI/Input/DatePicker'
import UIInput from '@/components/UI/Input/Input'
import InputNumber from '@/components/UI/Input/Number'

import useApi from '@/hooks/useApi'

const props = defineProps({
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        is_retour: false,
      }
    },
  },
})
const emit = defineEmits(['close'])
const { rit } = props

const form = ref({
  datum: undefined,
  tijd: undefined,
  vluchtnummer: '',
  personen: rit.personen_heen || rit.personen || 1,
  bagage: rit.bagage_heen || rit.bagage || 0,
  handbagage: rit.handbagage_heen || rit.handbagage || 0,
  kinderstoelen: rit.kinderstoelen_heen || rit.kinderstoelen || 0,
  kinderzitjes: rit.kinderzitjes_heen || rit.kinderzitjes || 0,
  maxicosis: rit.maxicosis_heen || rit.maxicosis || 0,
  aanpassingen_adressen_nodig: false,
  aanpassingen_adressen: '',
})

const api = useApi()
const handleOpslaan = () => {
  if (!form.value.datum || !form.value.tijd) {
    alert('Gelieve een datum en tijd in te vullen!')
    return
  }

  const feedback = [
    'Klant vroeg een retourreis aan voor deze rit:<br>',
    `Datum en tijd: ${form.value.datum} ${form.value.tijd}`,
    `Vluchtnummer: ${form.value.vluchtnummer}`,
    `Personen: ${form.value.personen}`,
    `Bagage: ${form.value.bagage}`,
    `Handbagage: ${form.value.handbagage}`,
    `Kinderstoelen: ${form.value.kinderstoelen}`,
    `Kinderzitjes: ${form.value.kinderzitjes}`,
    `Maxicosis: ${form.value.maxicosis}`,
  ]

  if (form.value.aanpassingen_adressen_nodig) {
    feedback[feedback.length - 1] += '<br>'
    feedback.push(`Adressen aanpassingen:<br>${form.value.aanpassingen_adressen}`)
  }

  const data = {
    rit_id: props.rit.id,
    is_retour: false,
    feedback: feedback.join('<br>- '),
  }

  api('RIT_FEEDBACK_CREATE', data).then(() => {
    emit('close')
  })
}
</script>
