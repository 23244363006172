<template>
  <span :class="{ hidden: !show }">
    <Portal to="modals" :disabled="!show">
      <div
        v-if="show"
        v-bind="$attrs"
        :class="modalLayer"
        class="absolute inset-0 overflow-y-auto custom-modal"
        role="dialog"
        aria-modal="true"
      >
        <div class="flex items-center justify-center min-h-screen text-center px-8">
          <div inert class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-backdrop" @click.self.stop="handleOutside"></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span inert class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div
            class="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle self-center sm:p-6"
            :class="{
              'max-w-lg w-full': !fullWidth,
              'max-w-7xl w-full': fullWidth,
              [modalLayer]: true,
            }"
          >
            <div class="sm:flex items-start gap-2">
              <div
                v-if="icon"
                class="mx-auto flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full sm:mx-0 sm:h-8 sm:w-8 mt-1"
                :class="{
                  'bg-red-100': icon.includes('text-red'),
                  'bg-blue-100': icon.includes('text-blue'),
                  'bg-green-100': icon.includes('text-green'),
                  'bg-yellow-100': icon.includes('text-yellow'),
                }"
                @click="$emit('clickIcon', true)"
              >
                <i :class="icon"></i>
              </div>
              <div class="mt-3 sm:mt-0 text-left flex-1">
                <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900">
                  {{ title }}
                </h3>
                <div :class="{ 'mt-2': false }">
                  <slot></slot>
                </div>
              </div>
            </div>
            <div v-if="close || confirm" class="mt-5 sm:mt-4 sm:flex sm:justify-between gap-x-2 pt-8">
              <button
                v-if="confirm"
                :class="confirmClass"
                type="button"
                class="btn success font-normal"
                @click="$emit('confirm', true); onClose()"
              >
                Confirm
              </button>
              <span></span>
              <button
                v-if="close"
                :class="closeClass"
                type="button"
                class="btn bg-red-100 text-red-900 font-normal px-2"
                @click="() => onClose()"
              >
                <i class="fas fa-times"></i>{{ closeText || 'Sluiten' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  </span>
</template>

<script setup>
import { computed, useSlots } from 'vue'
import { useVModel } from '@vueuse/core'
import { Portal } from 'portal-vue'

const props = defineProps({
  fixed: Boolean,
  modelValue: {
    type: [Boolean, Object],
    default: false,
  },
  modalLayer: String, // 'z-modal', 'z-notification', 'z-picker'
  fullWidth: Boolean,
  close: Boolean,
  confirm: Boolean,
  closeText: String,
  closeClass: String,
  confirmClass: String,
  title: String,
  icon: String,
})
const emit = defineEmits(['confirm', 'update:modelValue', 'clickIcon', 'close'])
const slots = useSlots()

const innerModel = useVModel(props, 'modelValue', emit, { passive: true })
const show = computed(() => (slots.default || props.title) && !!innerModel.value)

const onClose = () => {
  emit('close', true)
  innerModel.value = typeof props.modelValue === 'object' ? null : false
}

// const getActiveElement = () => {
//     return document.activeElement
// }

const handleOutside = () => {
  if (!props.fixed) onClose()
}
</script>
