<template>
  <ul>
    <li
      v-for="(item, index) in menuItems"
      :key="`${item.label}${item.to}${index}`"
      class="rounded-sm last:mb-0"
      :class="item.classesLi"
    >
      <component
        :is="item.to && !item.submenu ? 'router-link' : 'div'"
        :to="item.to && !item.submenu ? item.to : undefined"
        :target="item.target || undefined"
        class="nav-link"
        :class="{
          opened: opened === index,
          notActive: item.to && route?.fullPath && item.to.includes('?') && !route.fullPath.includes(item.to),
        }"
        @click="item.to && !item.submenu ? onNavigation(item) : undefined"
      >
        <div
          class="flex items-center"
          :class="{ 'pl-4': submenu && submenu.length }"
          @click="item.to && !item.submenu ? undefined : navClicked({ item, index })"
        >
          <div class="flex flex-grow items-center text-sm">
            <i class="fas flex-shrink-0 h-6 w-6 mr-2 flex items-center" :class="item.icon"></i>
            <span class="font-medium" :class="item.labelClass">{{ item.label }}</span>
          </div>
          <span
            v-if="item.counterKey && store.navigationCounters[item.counterKey]"
            class="nav-label text-white bg-teal-500"
          >{{ store.navigationCounters[item.counterKey] }}</span>
          <span v-if="item.submenu" class="text-white">
            <i
              :class="{ 'fa-chevron-down': opened === index, 'fa-chevron-up': opened !== index }"
              class="fas flex-shrink-0 h-6 w-6"
            ></i>
          </span>
        </div>
      </component>
      <UISidebarMenu
        v-if="item.submenu && opened === index"
        :submenu="item.submenu"
        class="opened"
        @click="opened = index"
        @navigation="$emit('navigation', $event)"
      />
    </li>
  </ul>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import UISidebarMenu from '@/components/UI/Sidebar/Menu'

const props = defineProps({
  menu: Array,
  submenu: Array,
})

const emit = defineEmits(['click', 'navigation'])
const route = useRoute()
const store = useStore()
const opened = ref(null)
const menuItems = props.submenu || props.menu || []

onMounted(() => {
  const router = useRouter()
  if (menuItems.length) {
    menuItems.some((item, index) => {
      if (item.submenu && item.submenu.length) {
        item.submenu.some(subitem => {
          if (subitem.to && router.currentRoute.value.path.includes(subitem.to)) {
            opened.value = index
            return true
          }
          return false
        })
      }
      return false
    })
  }
})

const onNavigation = item => {
  if (item.to) {
    emit('navigation', item.to)
    if (!item.submenu) {
      opened.value = null
    }
  }
}

const navClicked = ({ item, index }) => {
  emit('click', { item, index })
  if (!item || !item.submenu || !item.submenu.length) return
  opened.value = opened.value === index ? null : index
}
</script>

<style lang="scss" scoped>
.opened {
  background-color: lighten(#191f28, 1%);
}
.nav-label {
  @apply flex-shrink-0 ml-2 inline-flex items-center justify-center h-5 text-xs font-medium px-2 rounded-sm;
}
.nav-link {
  @apply px-4 lg:px-6 py-2 block text-gray-200 hover:text-white transition duration-150 outline-none;
  @apply border-l-4 border-transparent cursor-pointer;
  &:hover {
    @apply bg-gray-700;
  }
  &.router-link-exact-active:not(.notActive) {
    @apply border-l-4 border-teal-500;
    background-color: darken(#191f28, 2%);
    &:hover {
      background-color: #191f28;
    }
  }
}
</style>
