<template>
  <div v-if="model" class="flex flex-col gap-4">
    <UIInput
      v-model="model.name"
      label="Naam"
      type="text"
      class="w-full max-w-sm"
      placeholder="Naam ..."
    />
    <UIInput
      v-model="model.gemeente"
      label="Gemeente"
      type="text"
      class="w-full max-w-sm"
      placeholder="Gemeente ..."
    />
    <UIInput
      v-model="model.bericht"
      textarea
      label="Bericht"
      class="w-full max-w-sm"
      placeholder="Bericht ..."
    />
    <button class="btn success mt-4" @click="handleSubmit">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        name: '',
        bericht: '',
        gemeente: '',
      }
    },
  },
})

const emit = defineEmits(['update:modelValue', 'close'])

const api = useApi()

const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const handleSubmit = () => {
  api(model.value.id > 0 ? 'GASTENBOEK_EDIT' : 'GASTENBOEK_CREATE', model).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
