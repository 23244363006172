<template>
  <UISelectModalNullable
    v-bind="$attrs"
    v-model="innerValue"
    :label="label === false ? '' : (typeof label === 'string' ? label : 'Chauffeur')"
    :options="options"
    :placeholder="placeholder"
    search-placeholder="Chauffeur zoeken..."
  />
</template>

<script setup>
import { computed, defineEmits, defineProps, inject, onMounted, ref, toValue, watch } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectModalNullable from '@/components/UI/Select/SelectModalNullable.vue'

import { isEqualSoft } from '@/functions/isEqual'
import { isThisNaN } from '@/functions/isThisNaN'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const props = defineProps({
  label: [Boolean, String],
  useQuery: Boolean,
  skipMounted: Boolean,
  prefixItems: Array,
  suffixItems: Array,
  formatLabel: Function,
  mapChauffeur: Function,
  filterChauffeurs: Function,
  datum: [Date, String, Number],
  active: {
    type: Boolean,
    default: undefined,
  },
  disableAfwezig: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Selecteer een chauffeur',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])
const store = useStore()

const {
  bevestigdeRitten: bevestigdeRittenDTO,
  afwezigheden: afwezighedenDTO,
} = inject('kalender', { bevestigdeRitten: [], afwezigheden: [] })

const bevestigdeRitten = computed(() => toValue(bevestigdeRittenDTO || []) || [])
const afwezigheden = computed(() => toValue(afwezighedenDTO || {})?.list || [])

const options = computed(() => {
  const id = Number(props.modelValue || -1)
  let array = clone(store.state.chauffeurs || [])
  if (typeof props.active !== 'undefined') {
    array = array.filter(item => isEqualSoft(item.is_active, props.active) || isEqualSoft(item.value, id) || isEqualSoft(item.id, id))
  }
  if (typeof props.filterChauffeurs === 'function') {
    array = array.filter(props.filterChauffeurs)
  }
  if (props.prefixItems?.length) {
    array = [].concat(props.prefixItems, array)
  }
  if (props.suffixItems?.length) {
    array = [].concat(array, props.suffixItems)
  }

  return array.map(chauffeurElement => {
    let { ...chauffeur } = chauffeurElement

    if (typeof props.formatLabel === 'function') {
      chauffeur.label = props.formatLabel(chauffeur.label || '', chauffeur)
    }

    if (typeof props.mapChauffeur === 'function') {
      chauffeur = props.mapChauffeur(chauffeur)
    }

    chauffeur = mapChauffeurAfwezigheden(chauffeur, afwezigheden.value)
    chauffeur = mapChauffeurGeenCash(chauffeur)
    chauffeur = mapChauffeurToegekend(chauffeur, bevestigdeRitten.value)

    return chauffeur
  })
})

const safeModelValue = () => {
  if (props.modelValue > 0) innerValue.value = Number(props.modelValue)
  if (innerValue.value === '' || isThisNaN(innerValue.value)) innerValue.value = null
}

const innerValue = props.useQuery ? useRouteQueryInt('chauffeur', null) : ref(props.modelValue || null)
if (!props.useQuery) {
  onMounted(() => {
    watch(() => props.modelValue, () => {
      innerValue.value = props.modelValue || null
    })
  })
}
safeModelValue()

const emitValue = val => emit('update:modelValue', val)
watch(() => innerValue.value, (val, oldval) => {
  if (val === oldval) return
  emitValue(val)
})
if (!props.skipMounted) emitValue(innerValue.value)

/**
 * Chauffeur option formatting
 */

const mapChauffeurAfwezigheden = (chauffeur, afwezigheden = []) => {
  if (!props.datum || !Array.isArray(afwezigheden)) return chauffeur

  let found = false

  const datumVergelijken = new Date(props.datum)
  datumVergelijken.setSeconds(1)

  if (afwezigheden.length) {
    for (const afwezigheid of afwezigheden) {
      if (!afwezigheid || !afwezigheid.user_id || !afwezigheid.start || !afwezigheid.einde) continue
      if (!isEqualSoft(afwezigheid.user_id, chauffeur.value)) continue
      const start = new Date(afwezigheid.start)
      start.setSeconds(0)
      const einde = new Date(afwezigheid.einde)
      einde.setSeconds(59)
      found = !!(datumVergelijken.getTime() >= start.getTime() && datumVergelijken.getTime() <= einde.getTime())
      if (found) break
    }
  }

  if (found) {
    return {
      ...chauffeur,
      disabled: !!props.disableAfwezig,
      label: `📆 ${chauffeur.label || ''}`,
    }
  }

  return chauffeur
}

const mapChauffeurGeenCash = chauffeur => {
  if (props.isTeOntvangen && chauffeur.is_cash_toestaan === false) {
    chauffeur.label = `❌💵 ${chauffeur.label || ''}`
  }

  return chauffeur
}

const mapChauffeurToegekend = (chauffeur, bevestigdeRittenLijst = []) => {
  if (!chauffeur?.value || !Array.isArray(bevestigdeRittenLijst)) {
    return chauffeur
  }

  if (chauffeur.value === -1) {
    const aantalRittenNietToegekend = bevestigdeRittenLijst.reduce((prev, next) => {
      if (!next?.chauffeurs?.length || !!next?.chauffeurs?.find(c => !c.chauffeur_id)) {
        return prev + 1
      }
      return prev
    }, 0)
    chauffeur.label = `(${aantalRittenNietToegekend}) ${chauffeur.label || ''}`.trim()
    return chauffeur
  }

  const aantalRitten = bevestigdeRittenLijst.reduce((prev, next) => {
    if (next?.chauffeurs?.find(c => c.chauffeur_id === chauffeur.value)) {
      return prev + 1
    }
    return prev
  }, 0)

  chauffeur.label = `(${aantalRitten}) ${chauffeur.label || ''}`.trim()
  return chauffeur
}
</script>
