import { computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useBroadcastChannel } from '@vueuse/core'

import useWindow from '@/hooks/useWindow'
import useWindowCacheBust from '@/hooks/useWindowCacheBust'

const REFRESH_KEY = 'REFRESH_ALL'

export default options => {
  const { watchQuery } = options || {}

  const route = useRoute()
  const shouldRefresh = computed(() => route.query.refresh === REFRESH_KEY)
  const { isSupported: isBroadcastSupported, data: broadcastData, post: broadcast } = useBroadcastChannel({ name: `CHANNEL_${REFRESH_KEY}` })

  const refresh = options => {
    const { skipChannel } = options || {}
    if (isBroadcastSupported && !skipChannel) {
      broadcast(REFRESH_KEY)
    }
    if (!useWindow()) return
    useWindowCacheBust(() => {
      let newUrl = new URL(window.location.href)
      if (route.name === 'Refresh') {
        newUrl = new URL(window.location.origin)
      }
      newUrl.searchParams.set('refresh', REFRESH_KEY)
      window.location = newUrl.toString()
    })
  }

  const checkShouldRefresh = () => {
    if (!useWindow()) return
    if (shouldRefresh.value) {
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('refresh')
      window.location = newUrl.toString()
    }
  }

  if (isBroadcastSupported) {
    watch(broadcastData, refresh)
  }

  if (watchQuery) {
    onMounted(checkShouldRefresh)
    watch(shouldRefresh, checkShouldRefresh)
  }

  return refresh
}
