export default {
  STATS_OMZET_RITTEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/omzet-ritten' },
  STATS_OMZET_BOEKINGEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/omzet-boekingen' },
  STATS_GRAFIEK_RITTEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/grafiek-ritten' },
  STATS_GRAFIEK_BOEKINGEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/grafiek-boekingen' },
  STATS_CONTACTEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/contacten' },
  STATS_KLANTEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/klanten' },
  STATS_KLANTEN_TOP: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/klanten-top' },
  STATS_BEDRIJVEN_TOP: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/bedrijven-top' },
  STATS_BEDRIJVEN_BETAALTERMIJNEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/bedrijven-betaaltermijnen' },
  STATS_PERSONEEL: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/personeel' },
  STATS_BETALINGEN: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/betalingen' },
  STATS_PIPELINE: { method: 'GET', endpoint: '/api/dashboard/stats/dashboard/pipeline' },
  RITTEN_RENDABILITEIT: { method: 'GET', endpoint: '/api/dashboard/ritten/rendabiliteit' },
  RITTEN_WINSTMARGE_ONDERAANNEMING: { method: 'GET', endpoint: '/api/dashboard/ritten/winstmarge-onderaanneming' },
  CHIRON_RESERVATIE: { method: 'POST', endpoint: '/api/dashboard/chiron/reservatie' },

  KOSTEN_LIST: { method: 'GET', endpoint: '/api/dashboard/kosten/list' },
  KOST_CREATE: { method: 'POST', endpoint: '/api/dashboard/kosten/create' },
  KOST_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/kosten/delete' },
  KOST_BEWERKEN: { method: 'PATCH', endpoint: '/api/dashboard/kosten/edit' },

  TOEKKENNING_BEWERKING: { method: 'PATCH', endpoint: '/api/dashboard/toekenning/edit' },
  AFFILIATE_LIST: { method: 'GET', endpoint: '/api/dashboard/affiliates/list' },
  AFFILIATE_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/affiliates/delete' },

  BESCHIKBAARHEID_LIST: { method: 'GET', endpoint: '/api/dashboard/beschikbaarheden/administratie/list' },
  BESCHIKBAARHEID_CREATE: { method: 'POST', endpoint: '/api/dashboard/beschikbaarheden/create' },
  BESCHIKBAARHEID_BEWERKEN: { method: 'PATCH', endpoint: '/api/dashboard/beschikbaarheden/administratie/edit' },
  BESCHIKBAARHEID_ACCEPT: { method: 'PATCH', endpoint: '/api/dashboard/beschikbaarheden/administratie/accept' },
  BESCHIKBAARHEID_DENY: { method: 'PATCH', endpoint: '/api/dashboard/beschikbaarheden/administratie/deny' },
  CHAUFFEUR_BESCHIKBAARHEID_LIST: { method: 'GET', endpoint: '/api/dashboard/beschikbaarheden/me/list' },
  CHAUFFEUR_BESCHIKBAARHEID_CANCEL: { method: 'DELETE', endpoint: '/api/dashboard/beschikbaarheden/me/cancel' },

  CHAUFFEUR_SLAAPSTATUS: { method: 'PATCH', endpoint: '/api/dashboard/users/sleep' },
  WHITELIST_BLACKLIST_CHECK: { method: 'GET', endpoint: '/api/dashboard/whitelist-blacklist/check' },
  WHITELIST_BLACKLIST_CREATE: { method: 'POST', endpoint: '/api/dashboard/whitelist-blacklist/create' },
  WHITELIST_BLACKLIST_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/whitelist-blacklist/edit' },
  WHITELIST_BLACKLIST_LIST: { method: 'GET', endpoint: '/api/dashboard/whitelist-blacklist/list' },
  WHITELIST_BLACKLIST_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/whitelist-blacklist/delete' },
  NOTIFICATIE_CREATE: { method: 'POST', endpoint: '/api/dashboard/notificaties/create' },
  AFSPRAAK_CREATE: { method: 'POST', endpoint: '/api/dashboard/afspraken/create' },
  AFSPRAAK_BEWERKEN_INFO: { method: 'GET', endpoint: '/api/dashboard/afspraken/info-bewerken' },
  AFSPRAAK_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/afspraken/edit' },
  AFSPRAAK_LIST: { method: 'GET', endpoint: '/api/dashboard/afspraken/list' },
  AFSPRAAK_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/afspraken/delete' },
  MAANDELIJKS_LIST: { method: 'GET', endpoint: '/api/dashboard/kalender/list-maandelijks' },
  KALENDER_AFWEZIGHEID_LIST: { method: 'GET', endpoint: '/api/dashboard/kalender/afwezigheden' },
  KALENDER_WERKDAGEN_NA_ELKAAR_LIST: { method: 'GET', endpoint: '/api/dashboard/kalender/list-werkdagen-na-elkaar' },

  CUSTOM_OPTIONS_LIST: { method: 'GET', endpoint: '/api/dashboard/platform/options/list' },
  CUSTOM_OPTIONS_CREATE: { method: 'POST', endpoint: '/api/dashboard/platform/options/create' },
  CUSTOM_OPTIONS_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/platform/options/edit' },

  OFFERTE_CREATE: { method: 'POST', endpoint: '/api/dashboard/offerte/create' },
  OFFERTE_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/offerte/edit' },
  RIT_CREATE: { method: 'POST', endpoint: '/api/dashboard/ritten/create' },
  RIT_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/ritten/edit' },
  RIT_INFO: { method: 'GET', endpoint: '/api/dashboard/ritten/info' },
  RIT_BEWERKEN_INFO: { method: 'GET', endpoint: '/api/dashboard/ritten/info-bewerken' },
  RIT_PROP_VALUE: { method: 'PATCH', endpoint: '/api/dashboard/ritten/prop-value' },
  RIT_FEEDBACK_CREATE: { method: 'POST', endpoint: '/api/dashboard/feedback/create' },
  RIT_FEEDBACK_BEHANDELD: { method: 'PATCH', endpoint: '/api/dashboard/feedback/handled' },
  RIT_D: { method: 'DELETE', endpoint: '/api/dashboard/ritten/d' },
  RIT_BEVESTIG: { method: 'PATCH', endpoint: '/api/dashboard/ritten/bevestig' },
  RIT_ANNULEER: { method: 'PATCH', endpoint: '/api/dashboard/ritten/annuleer' },
  RIT_VOUCHER_GEVEN: { method: 'POST', endpoint: '/api/dashboard/vouchers/create-rit' },
  RIT_MEMO: { method: 'PATCH', endpoint: '/api/dashboard/ritten/memo' },
  RIT_VOUCHER_VERWIJDEREN: { method: 'DELETE', endpoint: '/api/dashboard/vouchers/delete-rit' },
  RIT_PASSAGIERS_LIST: { method: 'GET', endpoint: '/api/dashboard/passagiers/list' },
  RIT_PASSAGIERS_INGESTAPT: { method: 'PATCH', endpoint: '/api/dashboard/passagiers/toggle' },
  RIT_PASSAGIERS_CREATE: { method: 'POST', endpoint: '/api/dashboard/passagiers/create' },
  RIT_PASSAGIERS_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/passagiers/delete' },

  RIT_OPVOLG_ACTIES_LIST: { method: 'GET', endpoint: '/api/dashboard/opvolg-acties/list' },
  PRESTATIES_LIST: { method: 'GET', endpoint: '/api/dashboard/prestaties/list' },
  PRESTATIES_KALENDER: { method: 'GET', endpoint: '/api/dashboard/prestaties/kalender' },
  RIT_OPVOLG_ACTIES_CREATE: { method: 'POST', endpoint: '/api/dashboard/opvolg-acties/create' },

  ENQUETE_CREATE: { method: 'POST', endpoint: '/api/dashboard/enquetes/create' },
  ENQUETE_DATA: { method: 'GET', endpoint: '/api/dashboard/enquetes/data' },
  ENQUETE_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/enquetes/delete' },

  PLANNING_COLLEGAS: { method: 'GET', endpoint: '/api/dashboard/planning/collegas' },
  SETTING_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/settings/edit' },

  NORDIGEN_INSTITUTION_OPTIONS: { method: 'GET', endpoint: '/api/dashboard/nordigen/institutions/options' },
  NORDIGEN_REQUISITIONS_AANMAKEN: { method: 'POST', endpoint: '/api/dashboard/nordigen/requisitions/create' },
  NORDIGEN_REQUISITIONS_VERWIJDEREN: { method: 'DELETE', endpoint: '/api/dashboard/nordigen/requisitions/delete' },
  NORDIGEN_REQUISITIONS_LIST: { method: 'GET', endpoint: '/api/dashboard/nordigen/requisitions/list' },
  NORDIGEN_TRANSACTIONS_LIST: { method: 'GET', endpoint: '/api/dashboard/nordigen/transactions/list' },
  NORDIGEN_TRANSACTIONS_BEHANDELD: { method: 'PATCH', endpoint: '/api/dashboard/nordigen/transactions/behandeld' },

  ONDERAANNEMING_LIST: { method: 'GET', endpoint: '/api/dashboard/onderaanneming/list' },
  ONDERAANNEMING_SEND: { method: 'POST', endpoint: '/api/dashboard/onderaanneming/send' },
  ONDERAANNEMING_ACCEPT: { method: 'POST', endpoint: '/api/dashboard/onderaanneming/accept' },
  FINANCIAL_DOCS_STATS: { method: 'GET', endpoint: '/api/financial/docs/stats' },
  FINANCIAL_DOCS_LIST: { method: 'GET', endpoint: '/api/financial/docs/list' },
  FINANCIAL_DOCS_INFO: { method: 'GET', endpoint: '/api/financial/docs/info' },
  FINANCIAL_DOCS_NEXT: { method: 'GET', endpoint: '/api/financial/docs/next' },
  FINANCIAL_DOCS_CREATE: { method: 'POST', endpoint: '/api/financial/docs/create' },
  FINANCIAL_DOCS_EDIT: { method: 'PATCH', endpoint: '/api/financial/docs/edit' },
  FINANCIAL_DOCS_THEMA: { method: 'PATCH', endpoint: '/api/financial/docs/bedrijf_thema' },
  FINANCIAL_DOCS_DELETE: { method: 'DELETE', endpoint: '/api/financial/docs/delete' },
  FINANCIAL_DOCS_STATS_VOUCHERS: { method: 'GET', endpoint: '/api/financial/docs/stats-vouchers' },
  FINANCIAL_DOCS_SYNC_BETALINGEN: { method: 'POST', endpoint: '/api/financial/docs/sync-invoice-payments' },
  FINANCIAL_BETALING_CREATE: { method: 'POST', endpoint: '/api/financial/docs_payments/create' },
  FINANCIAL_DOCS_GENERATE_BY_DATE: { method: 'POST', endpoint: '/api/financial/docs/generate-by-date' },
  FINANCIAL_CONTACTS: { method: 'GET', endpoint: '/api/financial/email/contacts' },
  FINANCIAL_CONTACTS_STANDAARD: { method: 'PATCH', endpoint: '/api/financial/email/contact-standaard' },
  FINANCIAL_CONTACTS_DELETE: { method: 'GET', endpoint: '/api/financial/email/contact-delete' },
  FINANCIAL_MAILEN: { method: 'POST', endpoint: '/api/financial/email/send' },
  FINANCIAL_PASSAGIER_SALDO_LIST: { method: 'GET', endpoint: '/api/financial/passagier-saldo/list' },
  FINANCIAL_PASSAGIER_SALDO_BEHANDELD: { method: 'PATCH', endpoint: '/api/financial/passagier-saldo/handled' },
  RIT_CHAT_MESSAGES: { method: 'GET', endpoint: '/api/shared/chat/messages' },
  RIT_CHAT_SEND: { method: 'POST', endpoint: '/api/shared/chat/send' },
  PROMOTIE_INFO: { method: 'GET', endpoint: '/api/dashboard/vouchers/info' },

  KALENDER_TOEKENNEN: { method: 'PATCH', endpoint: '/api/dashboard/kalender/toekennen' },
  KALENDER_COMBINEREN: { method: 'PATCH', endpoint: '/api/dashboard/kalender/combineren' },
  RIT_BETALING_QR_CODE: { method: 'POST', endpoint: '/api/dashboard/betalingen/qr' },
  BETALING_CREATE: { method: 'POST', endpoint: '/api/dashboard/betalingen/create' },
  BETALING_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/betalingen/edit' },
  BETALING_DELETE: { method: 'DELETE', endpoint: '/api/dashboard/betalingen/delete' },
  SHIFT_SAVE: { method: 'PATCH', endpoint: '/api/dashboard/shiften/save' },
  USER_CREATE: { method: 'POST', endpoint: '/api/shared/auth/register-user' },
  USER_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/users/edit' },
  WAGEN_CREATE: { method: 'POST', endpoint: '/api/dashboard/wagens/create' },
  WAGEN_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/wagens/edit' },
  WAGEN_ONDERHOUD: { method: 'POST', endpoint: '/api/dashboard/wagens/onderhoud' },
  GASTENBOEK_CREATE: { method: 'POST', endpoint: '/api/dashboard/gastenboek/create' },
  GASTENBOEK_EDIT: { method: 'PATCH', endpoint: '/api/dashboard/gastenboek/edit' },
  AFFILIATE_UPSERT: { method: 'PATCH', endpoint: '/api/dashboard/affiliates/upsert' },
  API_AUTH_PASSWORD_CHANGE: { method: 'PATCH', endpoint: '/api/shared/auth/password-change' },
  GLOBAL_SEARCH: { method: 'GET', endpoint: '/api/dashboard/search' },

  GEOCODE_ADRES_NEXT: { method: 'GET', endpoint: 'https://dashboard.taxiboeken.be/api/dashboard/geocoding/next' },
  GEOCODE_ADRES_MERGE: { method: 'PATCH', endpoint: 'https://dashboard.taxiboeken.be/api/dashboard/geocoding/merge' },

  DUPLICATE_FACTURATIES_NEXT_UUID: { method: 'GET', endpoint: '/api/dashboard/duplicaten/facturaties/next-algo-uuid' },
  DUPLICATE_FACTURATIES_NEXT_ADRES: { method: 'GET', endpoint: '/api/dashboard/duplicaten/facturaties/next-algo-adres' },
  DUPLICATE_FACTURATIES_NEXT_BTW: { method: 'GET', endpoint: '/api/dashboard/duplicaten/facturaties/next-algo-btw' },
  DUPLICATE_FACTURATIES_NEXT_NAME: { method: 'GET', endpoint: '/api/dashboard/duplicaten/facturaties/next-algo-name' },
  DUPLICATE_FACTURATIES_MERGE: { method: 'PATCH', endpoint: '/api/dashboard/duplicaten/facturaties/merge' },
  FACTURATIE_DATA: { method: 'GET', endpoint: '/api/dashboard/facturatie/info' },
  KLANT_INFO_DATA: { method: 'GET', endpoint: '/api/dashboard/klanten/info?type=klanten_info' },
  KLANT_DATA: { method: 'GET', endpoint: '/api/dashboard/klanten/info?type=klanten' },
  CONTRACTEN_INFO: { method: 'GET', endpoint: '/api/dashboard/contracten/info' },
  CONTRACTEN_PRINT: { method: 'GET', endpoint: '/api/dashboard/contracten/print' },

  VOUCHERS_HISTORIEK: { method: 'GET', endpoint: '/api/dashboard/vouchers/historiek' },
  VOUCHERS_LIST: { method: 'GET', endpoint: '/api/dashboard/vouchers/list' },
}
