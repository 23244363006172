import router from '@/router'
import store from '@/store'

export const hasRoutePermission = route => {
  // console.log(route, store?.state?.profile)
  const perms = (route?.meta?.permissions || [])

  if (!perms?.length) return true
  if (perms.includes('EVERYONE') || perms.includes('*')) return true
  if (perms.includes('CONNECTED')) return store?.getters?.isAuthenticated

  if (!store?.state?.profile?.role) return false

  return perms.includes(store.state.profile.role)
}

export const hasRouteNamePermission = name => {
  const route = router.resolve({ name })
  return hasRoutePermission(route)
}

export const hasRoutePathPermission = path => {
  if (path?.length && path[0] !== '/') return true

  const route = router.resolve({ path })
  return hasRoutePermission(route)
}

export default hasRoutePermission
