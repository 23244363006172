import { computed, onBeforeUnmount, ref, unref } from 'vue'
import clone from 'just-clone'

import { axiosClient as axios } from '@/plugins/axios'

import API_ENDPOINTS from '@/constants/API_ENDPOINTS'

const AXIOS_METHODS_WITH_PARAMS = ['GET', 'DELETE']
const AXIOS_METHODS = ['GET', 'POST', 'PATCH', 'DELETE']

export default () => {
  const controllers = ref([])
  const loading = ref(false)

  const cancel = () => {
    if (!controllers?.value?.length) return
    const newControllersList = controllers.value.filter(el => {
      try {
        if (el.signal.aborted) return false
        if (el?.abort) {
          el.abort('Manually canceled')
          return false
        }
      } catch { /* Nothing */ }
      return !el.signal.aborted
    })
    controllers.value = newControllersList
  }

  onBeforeUnmount(() => cancel())

  const caller = async (_endpoint, apiData = {}, options = {}) => {
    let endpoint = _endpoint
    if (API_ENDPOINTS[_endpoint]) {
      caller.method = API_ENDPOINTS[_endpoint].method
      endpoint = API_ENDPOINTS[_endpoint].endpoint
    } else {
      if (!caller.method) {
        console.warn('Endpoint not registered in constants!', { method: caller.method, endpoint, apiData })
      }
      caller.method = caller.method || 'POST'
    }
    caller.method = caller.method.toUpperCase()

    let params = clone(unref(apiData || {}))
    if (AXIOS_METHODS_WITH_PARAMS.includes(caller.method) && !params.params) {
      params = { ...options, params }
    }

    let controller
    if (caller.method === 'GET' && typeof AbortController !== 'undefined') {
      // Make GET requests cancelable on unmount!
      controller = new AbortController()
      params.signal = controller.signal
      controllers.value.push(controller)
    }

    loading.value = true

    // eslint-disable-next-line no-useless-call
    const result = await axios[caller.method.toLowerCase()].call(axios, endpoint, params, options).finally(() => {
      loading.value = false
      if (controller) {
        controllers.value = controllers.value.filter(el => el !== controller)
        controller = null
      }
    })

    return result
  }

  AXIOS_METHODS.forEach(method => {
    caller[method.toLowerCase()] = async (x, y, z) => {
      caller.method = method.toUpperCase()
      return caller(x, y, z)
    }
  })

  caller.cancel = cancel
  caller.loading = computed(() => loading.value)
  caller.baseURL = axios.baseURL

  return caller
}
