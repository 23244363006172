<template>
  <span class="inline-flex items-center" :class="$attrs.class || ''">
    <input
      v-bind="Object.assign({}, $attrs, { class: '', placeholder, id, type })"
      :id="id"
      v-model="checked"
      :placeholder="placeholder || label"
      type="checkbox"
      class="input"
      @input="checked = $event.target.checked === 'on' || $event.target.checked === true"
      @focus="onFocus"
      @blur="onBlur"
    >
    <UIInputLabel
      v-if="label"
      :id="id"
      no-margin
      class="ml-2"
      :focusses="focussed"
    >{{ label }}</UIInputLabel>
  </span>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'

import UIInputLabel from '@/components/UI/Input/Label.vue'

const props = defineProps({
  label: String,
  id: {
    type: [Number, String],
    default: uuidv4,
  },
  type: {
    type: String,
    default: 'checkbox',
    validate(val) {
      return ['checkbox', 'radio'].includes(val)
    },
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [Number, String, Boolean],
    default() {
      return ''
    },
  },
})

const emit = defineEmits(['focus', 'blur', 'update:modelValue'])
const checked = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
const initialSafeCheckedValue = [true, 1, 'true', '1'].includes(checked.value)
if (checked.value !== initialSafeCheckedValue) {
  checked.value = initialSafeCheckedValue
}

const focussed = ref(false)

const onFocus = $event => {
  focussed.value = true
  emit('focus', $event)
}
const onBlur = $event => {
  focussed.value = false
  emit('blur', $event)
}
</script>
