<template>
  <AdressenNietGeocoded v-if="notGeocoded" />
  <div v-else class="flex flex-col gap-4">
    <!-- Legende -->
    <div>
      <div class="text-xs">
        <i class="fas fa-check"></i>
        <i class="fas fa-check text-white"></i>
        <span class="pl-2 -ml-1.5">Geselecteerd</span>
      </div>
      <div class="text-xs">
        <i class="fas fa-check"></i>
        <i class="fas fa-check -ml-1.5"></i>
        <span class="pl-2">Reeds verzonden</span>
      </div>
    </div>

    <!-- List -->
    <div class="input">
      <UIInputCheckbox v-model="selectAll" label="Alles selecteren?" class="ml-auto" />
      <UIInput v-model="filter" class="mb-2" />
      <RecycleScroller
        :ref="(el) => scroller = el"
        v-slot="{ item }"
        style="max-height: 250px; overflow-y: scroll;"
        :items="filteredItems"
        key-field="value"
        :item-size="32"
      >
        <button :disabled="isAlreadySend(item.value)" class="text-sm cursor-pointer hover:bg-purple-100 p-1 flex flex-row justify-between w-full" @click="handleSelect(item.value)">
          <span>{{ item.label }} <small v-if="isAlreadySend(item.value)" class="text-green-500">(Reeds verzonden)</small></span>
          <span>
            <i v-if="isSelected(item.value) || isAlreadySend(item.value)" class="fas fa-check mr-2"></i>
            <i v-if="isAlreadySend(item.value)" class="fas fa-check -ml-4"></i>
          </span>
        </button>
      </RecycleScroller>
    </div>

    <!-- Opslaan -->
    <button class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { useStore } from 'vuex'
import clone from 'just-clone'

import AdressenNietGeocoded from '@/components/UI/Banner/AdressenNietGeocoded.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInput from '@/components/UI/Input/Input'

import filterUnique from '@/functions/filterUnique'
import useApi from '@/hooks/useApi.js'
import useGetApi from '@/hooks/useGetApi.js'

const props = defineProps({
  notGeocoded: Boolean,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        type: null, // KALENDER TYPE
      }
    },
  },
})

const emit = defineEmits(['update:page', 'close'])

const store = useStore()
const api = useApi()

const apiData = computed(() => ({ rit_id: props.modelValue?.id }))
const { data } = useGetApi('ONDERAANNEMING_LIST', apiData, { watch: true })

watch(data, () => {
  // if (data.value?.list?.length) return
  items.value?.forEach(el => handleSelect(el.value))
})

const selected = ref([])
const filter = ref('')
const scroller = ref(null)

watch(filter, () => {
  if (scroller.value?.scrollToPosition) {
    scroller.value.scrollToPosition(0)
  }
})

const isSelected = value => selected.value.includes(value)
const isAlreadySend = value => data.value?.list?.includes(value)

const items = computed(() => store.getters.actieveOnderaannemers)
const filteredItems = computed(() => {
  if (!filter.value) return items.value
  const stringSearch = String(filter.value).toLowerCase()
  const numberSearch = stringSearch.replace(/\D/g, '')
  return items.value.filter(el => {
    if (el.label.toLowerCase().includes(stringSearch)) return true
    return numberSearch && String(el.user_id).includes(numberSearch)
  })
})

const handleSelect = value => {
  if (isAlreadySend(value)) return
  if (isSelected(value)) {
    selected.value = selected.value.filter(el => el !== value)
    return
  }
  selected.value.push(value)
  selected.value = selected.value.filter(filterUnique)
}

const selectAll = computed({
  get() {
    return selected.value?.length === items.value?.length
  },
  set(v) {
    if (!v) {
      selected.value = []
      return
    }
    selected.value = clone(items.value).map(el => el?.value)
  },
})

const handleOpslaan = () => {
  api('ONDERAANNEMING_SEND', {
    rit_id: props.modelValue.id,
    user_ids: selected.value,
    is_retour: props.modelValue.type === 'RETOUR_TERUG',
  }).then(() => {
    emit('close')
  })
}
</script>
