<template>
  <p v-if="!items?.length" class="no-data block text-center text-base">
    {{ noDataText }}
  </p>
  <div v-else class="table-wrapper">
    <div class="border border-dashboard-dark">
      <table class="w-full">
        <slot name="colgroup"></slot>
        <thead :class="{ hidden: hideHeaders }">
          <tr>
            <th v-if="select">
              <UIInputCheckbox v-if="selectAll" :model-value="items.length && selectedVModel.length === items.length" @update:model-value="handleSelectAllChanged" />
            </th>
            <th v-for="h in prefixHeaders" :key="h">
              {{ formatHeader(h) }}
            </th>
            <th v-for="(h, index) in headersArray" :key="`${h}${index}${headersArray.length}`">
              <slot :name="`header-${index}`">
                <span :class="h.class">
                  {{ formatHeader(h.label || h.text || h) }}
                </span>
              </slot>
            </th>
            <th v-for="h in suffixHeaders" :key="h">
              {{ formatHeader(h) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="JSON.stringify(item)" class="relative">
            <td v-if="select">
              <UIInputCheckbox :model-value="selectedVModel.includes(item)" @update:model-value="$event ? selectionAdd([item]) : selectionRemove([item])" />
            </td>
            <td v-for="h in prefixHeaders" :key="h">
              <slot :name="`item-${h}`" :item="item" :index="index">
                <span>&nbsp;</span>
              </slot>
            </td>
            <template v-if="headers && headers.length">
              <td v-for="h in headersArray" :key="h" class="top-0">
                <slot :name="`item-${h}`" :item="item" :index="index">
                  <span>{{ item[h] || item[h.toLowerCase()] || ' ' }}</span>
                </slot>
              </td>
            </template>
            <template v-else>
              <td v-for="(value, key) in item" :key="`${key}${value}`" class="top-0">
                <slot :name="`item-${key}`" :item="item" :index="index">
                  <template v-if="checkbox.includes(key)">
                    <span :key="item[key]" class="block w-8">
                      <UIInputCheckbox v-model="item[key]" :class="{ 'cursor-not-allowed': !editable }" />
                    </span>
                  </template>
                  <template v-else-if="value === true || value === false">
                    {{ value ? 'Ja' : 'Neen' }}
                  </template>
                  <template v-else>
                    {{ value || value === 0 || value === false ? value : '/' }}
                  </template>
                </slot>
              </td>
            </template>
            <td v-for="h in suffixHeaders" :key="`${h}-${index}`">
              <slot :name="`item-${h}`" :item="item" :index="index">
                <span>slot: item-{{ h }}</span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInputCheckbox from '@/components/UI/Input/Checkbox.vue'

import { ARRAY_DEFAULT_EMPTY } from '@/constants/PROPS'
import { formatHeader } from '@/functions/formatText'

const props = defineProps({
  inputs: ARRAY_DEFAULT_EMPTY,
  checkbox: ARRAY_DEFAULT_EMPTY,
  items: ARRAY_DEFAULT_EMPTY,
  suffixHeaders: ARRAY_DEFAULT_EMPTY,
  prefixHeaders: ARRAY_DEFAULT_EMPTY,
  headers: ARRAY_DEFAULT_EMPTY,
  hideHeaders: Boolean,
  headersReplace: Object,
  editable: Boolean,
  select: Boolean,
  selectAll: Boolean,
  selected: Array,
  noDataText: {
    type: String,
    default: 'No records found',
  },
})

const emit = defineEmits(['update:selected'])

const selectedVModel = useVModel(props, 'selected', emit, { deep: true, passive: true })
const selectionRemove = items => {
  selectedVModel.value = selectedVModel.value.filter(el => items.includes(el))
}
const selectionAdd = items => {
  selectedVModel.value = [].concat(selectedVModel.value, items).filter((el, index, self) => self.indexOf(el) === index)
}
const handleSelectAll = () => {
  if (selectedVModel.value.length === props.items.length) return
  const { items } = props
  selectionAdd(items)
}
const handleDeselectAll = () => {
  if (selectedVModel.value.length === 0) return
  selectedVModel.value = []
}
const handleSelectAllChanged = shouldSelectAll => {
  if (shouldSelectAll) {
    handleSelectAll()
    return
  }
  if (selectedVModel.value.length === props.items.length) {
    return handleDeselectAll()
  }
}

const headersArray = computed(() => {
  const { headers, items, headersReplace } = props
  let result = []
  if (headers && headers.length) result = headers
  else if (items && items.length) result = Object.keys(items[0])
  if (result.length && headersReplace) {
    result = result.map(k => headersReplace[k] || k)
  }
  return result
})
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: auto;
  border-radius: 0;
  width: 100%;
  padding: 0;
  outline: 0;
  // border: 1px solid #202935;
}

table tr,
table tr td,
table>tr,
table>tr>td,
table,
tr,
td {
  word-break: keep-all;
  white-space: nowrap;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  margin: 0;
}

th,
td {
  text-align: left;
  padding: 8px 12px 8px 12px;
}

th {
  font-weight: bold;
  background-color: #202935;
  color: #ffffff;
}

table input[type='text'],
table input[type='password'],
table input[type='email'],
table select {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
}

thead {
  background-color: #f2f2f2;
}

tbody tr:nth-child(odd),
tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #fdfdfd;
  border-top: 1px solid #dddddd;
}

tbody tr:nth-child(even),
tbody tr:nth-child(even) td,
tbody tr:nth-child(even) th {
  background-color: #e2e2e2;
  border-top: 1px solid #dddddd;
}
</style>
