<template>
  <div v-if="vModelRit.id > 0" class="flex flex-col gap-4">
    <div>
      <UIInput
        v-model="form.planning_optie_heen"
        textarea
        :label="vModelRit.is_retour ? 'Interne memo heen' : 'Interne memo'"
        rows="4"
        class="w-full mb-0 pb-0"
        placeholder="Interne memo heen ..."
      />
      <small class="text-xs text-gray-500 block">Deze memo geldt voor: {{ dateTimeString(vModelRit.vertrek_datetime) }}</small>
    </div>
    <div>
      <UIInput
        v-if="vModelRit.is_retour"
        v-model="form.planning_optie_retour"
        textarea
        label="Interne memo retour"
        rows="4"
        class="w-full mb-0 pb-0"
        placeholder="Interne memo retour ..."
      />
      <small v-if="vModelRit.is_retour" class="text-xs text-gray-500 block">Deze memo geldt voor: {{ dateTimeString(vModelRit.retour_datetime) }}</small>
    </div>
    <button :disabled="api.loading.value" class="btn success inline-block" @click="handleOpslaan">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import { dateTimeString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'

const props = defineProps({
  rit: {
    type: Object,
    default() {
      return {
        id: null,
        planning_optie_heen: null,
        is_retour: false,
        planning_optie_retour: null,
      }
    },
  },
})

const emit = defineEmits(['close', 'update:rit'])
const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const form = ref({
  id: props.rit.id,
  planning_optie_heen: props.rit.planning_optie_heen,
  planning_optie_retour: !props.rit.is_retour ? null : props.rit.planning_optie_retour,
})

const api = useApi()

const handleOpslaan = () => {
  api('RIT_MEMO', form).then(() => {
    vModelRit.value.planning_optie_heen = form.value.planning_optie_heen
    vModelRit.value.planning_optie_retour = form.value.planning_optie_retour
    emit('close')
  })
}
</script>
