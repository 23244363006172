<template>
  <div class="flex flex-col gap-3">
    <UISelectWagen
      v-model="record.wagen_id"
      class="w-full max-w-sm"
      active
      label
    />
    <UIInput
      v-model="record.kilometerstand"
      label="Kilometerstand"
      type="number"
      class="w-full max-w-sm"
      placeholder="Kilometerstand ..."
    />
    <button class="btn success inline-block">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import UIInput from '@/components/UI/Input/Input'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'

const record = ref({
  wagen_id: null,
  kilometerstand: '',
})
</script>
