<template>
  <div v-if="innerValue" class="flex flex-col gap-4 max-w-sm mx-auto">
    <UIInput
      v-model="innerValue.label"
      label="Label"
      type="text"
      class="w-full"
      placeholder="Label ..."
    />
    <UIInput
      v-model="innerValue.value"
      label="Waarde (Automatisch)"
      type="text"
      class="w-full"
      placeholder="Waarde ..."
      disabled
    />
    <button :disabled="!innerValue.label || !innerValue.value" class="btn success mt-4" @click="handleSubmit">
      Opslaan
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import useSlug from '@/hooks/useSlug'

const props = defineProps({
  callback: Function,
  optionsTable: String,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        label: '',
        value: '',
      }
    },
  },
})

const emit = defineEmits(['update:modelValue', 'close'])

const store = useStore()

const innerValue = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const focussedValue = ref(false)
watch(() => innerValue?.value?.label, () => {
  if (focussedValue.value) return
  innerValue.value.value = useSlug(innerValue.value.label, '_').toUpperCase()
})

const handleSubmit = () => {
  store.dispatch(innerValue.value.id > 0 ? 'API_CUSTOM_OPTIONS_EDIT' : 'API_CUSTOM_OPTIONS_CREATE', { ...innerValue.value, table: props.optionsTable }).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
